import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography, Tooltip, IconButton, Stack, Link, Grid } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import Button, { ButtonProps } from '@mui/material/Button';
import './Login.css';
import Logo from '../../assets/img/common/logo.png';
import LeftImg from '../../assets/img/common/left.png';
import { ReactComponent as MicrosoftIcon } from '../../assets/img/common/microsoft_og.svg';
import { ReactComponent as FbIcon } from '../../assets/img/common/facebook_og.svg';
import { ReactComponent as GoogleIcon } from '../../assets/img/common/google_og.svg';
import CustomTextField from '../../components/CustomTextField';
import { useDispatch } from 'react-redux';
import { updateCommon } from '../../reduxstate/slices/portalSlice';
import { updateUser } from '../../reduxstate/slices/userSlice';
import { common } from '../../assets/jss/messages';
import { IResolveParams, LoginSocialFacebook, LoginSocialGoogle, LoginSocialMicrosoft } from 'reactjs-social-login';
import apiQuery from '../../apicall/apiQuery';
import { Cookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { RootState } from '../../reduxstate/index';
import { getDomain, getCurrentUser, getUserData } from '../../shared';
import Processing from '../../components/Processing';
import CustomCheckBox from '../../components/CustomCheckBox';

const LoginPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	color: '#FFFF',
	height: '90vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	borderRadius: '2vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsRegular',
	fontSize: '1vmax',
	justifyContent: 'center',
	width: '10vw',
	'&:hover': {
		background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	},
}));

const Login: React.FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const cookies = new Cookies();
	const { sysPref, workflow, redirectURL } = useSelector((state: RootState) => state.portal);
	const { userData: user } = useSelector((state: RootState) => state.user);
	const { GOOGLE_APP_ID, FB_APP_ID, MICROSOFT_APP_ID } = sysPref;
	const REDIRECT_URI = `${window.location.origin}${window.location.pathname}`;
	const [isProceesing, setIsProceesing] = React.useState(false);
	const [isError, setError] = React.useState(false);
	const [isNotValid, setNotValid] = React.useState('false');
	const params = new URLSearchParams(window.location.search);
	const redirect_url: any = params.get('redirect_url');

	useEffect(() => {
		if (user) {
			if (!isEmptyOrSpaces(redirect_url)) window.location.replace(decodeURIComponent(redirect_url));
			else navigate('/');
		}
		// eslint-disable-next-line
	}, [user]);

	const isEmptyOrSpaces = (value: string | null | undefined) => {
		return value === undefined || value === null || value === '';
	};

	const regex = {
		email: new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'),
		number: new RegExp('^[0-9]+$'),
		password: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
	};

	const [userData, setUserData] = React.useState({
		data: {
			email: '',
			password: '',
			rememberme: false,
		},
		error: {
			email: false,
			password: false,
			rememberme: false,
		},
		errorMessage: {
			required: common.required,
			email: common.required,
		},
	});

	const handleNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const checked = event.target.checked;
		setUserData({
			...userData,
			data: {
				...userData.data,
				[name]: checked,
			},
		});
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const value = event.target.value;
		let isError = false;
		let isEmail = false;
		if (event.target.required) {
			if (isEmptyOrSpaces(value)) {
				isError = true;
			}
		}
		if (name === 'email') {
			if (event.target.required) {
				isEmail = regex.email.test(value);
				if (isEmptyOrSpaces(value) || !isEmail) {
					isError = true;
				}
			} else if (!isEmptyOrSpaces(value)) {
				isEmail = regex.email.test(value);
				if (!isEmail) {
					isError = true;
				}
			}
		}

		setError(false);
		setNotValid('false');

		setUserData({
			...userData,
			data: {
				...userData.data,
				[name]: event.target.value,
			},
			error: {
				...userData.error,
				[name]: isError,
			},
			errorMessage: {
				...userData.errorMessage,
				[name]: isError ? (isEmptyOrSpaces(value) ? common.required : common.invalid_email) : common.required,
			},
		});
	};

	const validateAll = () => {
		let isValidData = true;
		let email = false;
		let password = false;
		let errorEmail = false;

		if (isEmptyOrSpaces(userData.data.email)) {
			email = true;
		} else if (!regex.email.test(userData.data.email)) {
			errorEmail = true;
			email = true;
		}
		if (isEmptyOrSpaces(userData.data.password)) {
			password = true;
		}

		if (email || password) {
			isValidData = false;
		}

		setUserData({
			...userData,
			error: {
				...userData.error,
				email: email,
				password: password,
			},
			errorMessage: {
				...userData.errorMessage,
				email: errorEmail ? common.invalid_email : common.required,
			},
		});

		setError(false);
		setNotValid('false');
		return isValidData;
	};

	const successLogin = async (data: any, onload?: boolean, remember?: boolean) => {
		try {
			const domain = getDomain();
			cookies.remove('access_token');
			cookies.remove('refresh_token');
			if (!remember) {
				cookies.set('access_token', data.access_token, domain);
				cookies.set('refresh_token', data.refresh_token, domain);
			} else {
				cookies.set('access_token', data.access_token, { path: domain.path, maxAge: 10 * 365 * 24 * 60 * 60 * 1000, domain: domain.domain });
				cookies.set('refresh_token', data.refresh_token, { path: domain.path, maxAge: 10 * 365 * 24 * 60 * 60 * 1000, domain: domain.domain });
			}

			const user = await getCurrentUser();
			if (user) {
				dispatch(updateUser({ userData: user }));
				getUserDetails();
				setIsProceesing(false);
			} else {
				if (!onload) FailLogin('Error while getting user data');
			}
		} catch (error) {
			if (!onload) FailLogin(error);
		}
	};

	const getUserDetails = async () => {
		const regUser = await getUserData();
		if (regUser) {
			dispatch(updateUser({ registerData: regUser }));
			if (!isEmptyOrSpaces(redirect_url)) {
				window.location.replace(decodeURIComponent(redirect_url));
			} else if ((workflow === 'diagnostic' || workflow === 'fulllength') && !isEmptyOrSpaces(redirectURL)) {
				window.location.replace(redirectURL);
			} else if (workflow === 'enroll' && !isEmptyOrSpaces(redirectURL)) {
				const enroll_course_id: string | null = sessionStorage.getItem('enroll_course_id');
				const session_redirect_url: string | null = sessionStorage.getItem('redirect_url');
				let isEnrolled = false;
				if (regUser && regUser?.enrolledCourses && regUser?.enrolledCourses?.includes(Number(enroll_course_id))) {
					isEnrolled = true;
				}
				if (isEnrolled) {
					sessionStorage.removeItem('redirect_url');
					sessionStorage.removeItem('enroll_course_id');
					dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'info', message: common.duplicate_enroll } }));
					navigate(session_redirect_url ? session_redirect_url : '/');
				} else {
					window.location.replace(redirectURL);
				}
			} else {
				navigate('/');
			}
		} else {
			if (!isEmptyOrSpaces(redirect_url)) navigate(`/registration?redirect_url=${redirect_url}`);
			else navigate('/registration');

			// if (!isEmptyOrSpaces(redirect_url)) window.location.replace(decodeURIComponent(redirect_url));
			// else if (workflow === 'diagnostic' && !isEmptyOrSpaces(redirectURL)) window.location.replace(redirectURL);
			// else navigate(workflow === 'enroll' || workflow === 'fulllength' ? '/registration' : '/');
		}
	};

	const FailLogin = (error: any) => {
		// dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.invalid_user } }));
		if (error?.reason?.statusCode === 406) {
			setNotValid('true');
		} else {
			setError(true);
		}
		console.error('Login failed:', error);
		setIsProceesing(false);
	};

	const checkLogin = (email: string, password: string, loginType?: string, accessToken?: string, rememberme?: boolean) => {
		try {
			const body = {
				email: email,
				password: password,
				loginType: loginType,
				accessToken: accessToken,
				rememberme: rememberme,
			};
			apiQuery('auth/login', {}, 'POST', body)
				.then((result) => {
					successLogin(result, undefined, rememberme);
				})
				.catch((error) => {
					FailLogin(error);
				});
		} catch (error) {
			FailLogin(error);
		}
	};

	const sucessSignup = (user: any) => {
		const auth = ['GOOGLE', 'FACEBOOK', 'MICROSOFT', 'APPLE'];
		if (auth.includes(user.loginType.toUpperCase())) {
			checkLogin(user.email, user.loginType, user.loginType, user.accessToken);
		} else {
			setIsProceesing(false);
			// dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'success', message: common.success_registration } }));
			navigate('/login');
		}
	};

	const createUser = (user: any) => {
		try {
			apiQuery('register/user', {}, 'POST', user)
				.then((result: any) => {
					sucessSignup(user);
				})
				.catch((error: any) => {
					FailLogin(error);
				});
		} catch (error) {
			FailLogin(error);
		}
	};

	const checkSocialLogin = (user: any) => {
		setIsProceesing(true);
		apiQuery('auth/getsocialuser/' + user.email, {}, 'GET', {})
			.then((res) => {
				if (res === true) {
					checkLogin(user.email, user.password, user.loginType, user.accessToken);
				} else {
					createUser(user);
				}
			})
			.catch((err) => {
				FailLogin(err);
			});
	};

	const handleRegularLogin = async () => {
		try {
			const validate = validateAll();
			if (validate) {
				setIsProceesing(true);
				checkLogin(userData.data.email, userData.data.password, 'REGULAR', undefined, userData.data.rememberme);
			}
		} catch (error) {
			FailLogin(error);
		}
	};

	const handleGoogleLoginSuccess = (data: any) => {
		const newUser = {
			email: data.email,
			password: 'GOOGLE',
			firstName: data.given_name,
			lastName: data.family_name,
			loginType: 'GOOGLE',
			validated: true,
			accessToken: data.access_token,
		};
		checkSocialLogin(newUser);
	};

	const handleGoogleLoginFail = (error: any) => {
		FailLogin(error);
	};

	const handleFacebookLoginSuccess = (data: any) => {
		const newUser = {
			email: data.email,
			password: 'FACEBOOK',
			firstName: data.first_name,
			lastName: data.last_name,
			loginType: 'FACEBOOK',
			validated: true,
			accessToken: data.accessToken,
		};
		checkSocialLogin(newUser);
	};

	const handleFacebookLoginFail = (error: any) => {
		FailLogin(error);
	};

	const handleMicrosoftLoginSuccess = (data: any) => {
		const newUser = {
			email: data.mail,
			password: 'MICROSOFT',
			firstName: data.givenName,
			lastName: data.surname,
			loginType: 'MICROSOFT',
			validated: true,
			accessToken: data.access_token,
		};
		checkSocialLogin(newUser);
	};

	const handleMicrosoftLoginFail = (error: any) => {
		FailLogin(error);
	};

	const handleSendValidationEmail = () => {
		let email = false;
		let errorEmail = false;

		if (isEmptyOrSpaces(userData.data.email)) {
			email = true;
		} else if (!regex.email.test(userData.data.email)) {
			errorEmail = true;
			email = true;
		}

		if (email) {
			setUserData({
				...userData,
				error: {
					...userData.error,
					email: email,
				},
				errorMessage: {
					...userData.errorMessage,
					email: errorEmail ? common.invalid_email : common.required,
				},
			});
		} else {
			apiQuery('auth/sendverifyemail/' + userData.data.email, {}, 'GET', {})
				.then((res) => {
					setNotValid('sent');
					setUserData({
						...userData,
						data: {
							...userData.data,
							email: '',
							password: '',
						},
						error: {
							...userData.error,
							email: false,
							password: false,
						},
					});
				})
				.catch((err) => {
					FailLogin(err);
				});
		}
	};

	const handleJoinus = () => {
		navigate('/joinus');
	};

	const handleForgotPassword = () => {
		navigate('/forgotPassword');
	};

	const handleLogoClick = () => {
		dispatch(updateCommon({ redirectURL: '' }));
		dispatch(updateCommon({ workflow: '' }));
		navigate('/');
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			handleRegularLogin();
		}
	};

	return (
		<LoginPaper>
			<Processing isProceesing={isProceesing} />
			<div className='login_header'>
				<div className='login_banner'></div>
				<img className='login_logo' alt='' src={Logo} onClick={handleLogoClick} />
			</div>
			<div className='login_content'>
				<div className='login_left'>
					<img className='left_image' alt='' src={LeftImg} />
				</div>
				<div className='login_right'>
					<Typography className='hello_text'>Hello!</Typography>
					<Typography className='sign_in_text'>Sign In To Your Account</Typography>
					<Stack className='social_icon_list' direction={'row'}>
						<LoginSocialGoogle
							client_id={process.env.REACT_APP_GOOGLE_APP_ID || GOOGLE_APP_ID}
							scope='openid profile email'
							onResolve={({ provider, data }: IResolveParams) => {
								handleGoogleLoginSuccess(data);
							}}
							onReject={(err) => {
								handleGoogleLoginFail(err);
							}}
						>
							<Tooltip title='Google Login'>
								<IconButton edge='end' color='inherit' aria-controls='menu' aria-haspopup='true'>
									<GoogleIcon className='social_icon' />
								</IconButton>
							</Tooltip>
						</LoginSocialGoogle>

						<LoginSocialFacebook
							appId={process.env.REACT_APP_FB_APP_ID || FB_APP_ID}
							onResolve={({ provider, data }: IResolveParams) => {
								handleFacebookLoginSuccess(data);
							}}
							onReject={(err) => {
								handleFacebookLoginFail(err);
							}}
						>
							<Tooltip title='Facebook Login'>
								<IconButton edge='end' color='inherit' aria-controls='menu' aria-haspopup='true'>
									<FbIcon className='social_icon' />
								</IconButton>
							</Tooltip>
						</LoginSocialFacebook>
						<LoginSocialMicrosoft
							client_id={process.env.REACT_APP_MICROSOFT_APP_ID || MICROSOFT_APP_ID}
							redirect_uri={REDIRECT_URI}
							onResolve={({ provider, data }: IResolveParams) => {
								handleMicrosoftLoginSuccess(data);
							}}
							onReject={(err) => {
								handleMicrosoftLoginFail(err);
							}}
						>
							<Tooltip title='Microsoft Login'>
								<IconButton edge='end' color='inherit' aria-controls='menu' aria-haspopup='true'>
									<MicrosoftIcon className='social_icon' />
								</IconButton>
							</Tooltip>
						</LoginSocialMicrosoft>
					</Stack>
					<Stack direction={'column'} justifyContent={'center'} spacing={2}>
						<CustomTextField
							label='EMAIL ID'
							name='email'
							type='text'
							required
							className='input_field'
							error={userData.error.email}
							helperText={userData.errorMessage.email}
							value={userData.data.email}
							onChange={handleChange}
							onKeyDown={handleKeyDown}
						/>
						<CustomTextField
							label='PASSWORD'
							name='password'
							type='password'
							required
							className='input_field'
							error={userData.error.password}
							helperText={userData.errorMessage.required}
							value={userData.data.password}
							onChange={handleChange}
							onKeyDown={handleKeyDown}
						/>
					</Stack>
					<Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'} marginTop={1} marginLeft={20}>
						<Grid item xs sx={{ display: 'flex', color: 'black' }}>
							<CustomCheckBox label='Stay signed in' name='rememberme' alignment='left' checked={userData.data.rememberme} onChange={handleNotification} />
						</Grid>
					</Stack>
					{isError && (
						<>
							<br />
							<Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
								<Typography className='login_error'>
									<b>Error:</b> {common.invalid_user}
								</Typography>
							</Stack>
						</>
					)}
					{isNotValid === 'true' && (
						<>
							<br />
							<Stack direction={'column'} spacing={1} alignItems={'center'} justifyContent={'center'}>
								<Typography className='login_error'>
									<b>Error:</b> Please verify your email id to login
								</Typography>
								<Link component='button' underline='hover' className='forget_pwd_text' onClick={handleSendValidationEmail}>
									Click here to send verfication mail again
								</Link>
							</Stack>
						</>
					)}
					{isNotValid === 'sent' && (
						<>
							<br />
							<Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
								<Typography className='login_success'>Verification mail is sent to your email id</Typography>
							</Stack>
						</>
					)}
					<Stack className='botton_div' direction={'row'}>
						<CustomButton variant='contained' onClick={handleJoinus}>
							REGISTER
						</CustomButton>
						<CustomButton type='submit' variant='contained' onClick={handleRegularLogin}>
							LOGIN
						</CustomButton>
					</Stack>
					<Link component='button' underline='hover' className='forget_pwd_text' onClick={handleForgotPassword}>
						Forgot Password?
					</Link>
				</div>
			</div>
		</LoginPaper>
	);
};

export default Login;
