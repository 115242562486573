import React from 'react';
import { hydrate } from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './reduxstate';

const AppElement = () => (
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>
);

const rootElement = document.getElementById('root');
if (rootElement && rootElement.hasChildNodes()) {
	hydrate(<AppElement />, rootElement);
} else {
	const root = createRoot(rootElement!);
	root.render(<AppElement />);
}

reportWebVitals();
