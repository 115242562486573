import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

export const getInitData = createAsyncThunk('user/getInitData', async () => {
  const data = [
    {id: 1, name: 'portal 1'},
    {id: 2, name: 'portal 2'},
    {id: 3, name: 'portal 3'}
  ];
  return data;
});

interface InitialStateTypes {
  data: Array<Object>;
  status: `idle` | `busy` | `failed` | `success`;
  error: null | string;
  loginData: any;
  userData: any;
  registerData: any;
}

const initialState: InitialStateTypes = {
  data: [],
  status: 'idle',
  error: null,
  loginData: null,
  userData: null,
  registerData: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    insertData: (state, action: PayloadAction<Object>) => {
      state.data.push(action.payload);
    },
    updateUser: (state, action: PayloadAction<Partial<InitialStateTypes>>) => {
      Object.assign(state, action.payload);
      if (action.payload.hasOwnProperty('userData')) {
        localStorage.setItem('userData', JSON.stringify(state.userData));
      }
    }
  }
});

export const { insertData, updateUser } = userSlice.actions;

export default userSlice.reducer;
