import './OurTechnology.css';
import { ReactComponent as ClassSupport } from '../../assets/img/technology/classSupport.svg';
import { ReactComponent as PrivateTutoring } from '../../assets/img/technology/privateTutoring.svg';
import { ReactComponent as Diagnostic } from '../../assets/img/technology/diagnostic.svg';
import { ReactComponent as StudyPlan } from '../../assets/img/technology/studyPlan.svg';
import { ReactComponent as Database } from '../../assets/img/technology/database.svg';

import { FunctionComponent } from 'react';

const LoginDev: FunctionComponent = () => {
	return (
		<div className='technology-support'>
			<div className='technology-support1-parent'>
				<div className='technology-support1'>OUR CUTTING-EDGE TECHNOLOGY</div>
				<div className='tech-frame-child' />
			</div>
			<div className='technology-support-parent'>
				<div className='tech-frame-parent1'>
					<div className='class-parent'>
						<div className='technology-support1-icon'>
							<ClassSupport />
						</div>
						<div className='class-support'>Class Support</div>
						<div className='class-support-desc'>Our online study center acts as a supplement and resource to students enrolled in our classes.</div>
					</div>
					<div className='class-parent'>
						<div className='technology-support1-icon'>
							<PrivateTutoring />
						</div>
						<div className='class-support'>Private Tutoring Support</div>
						<div className='class-support-desc'>With our study center, tutors can anticipate student weaknesses, manage study plans, set goals, and track progress.</div>
					</div>
				</div>
				<div className='tech-frame-parent1'>
					<div className='class-parent'>
						<div className='technology-support1-icon'>
							<Diagnostic />
						</div>
						<div className='class-support'>Diagnostic</div>
						<div className='class-support-desc'>Our diagnostics measure students’ strengths and weaknesses relative to the test and suggests an optimized study plan.</div>
					</div>
					<div className='class-parent'>
						<div className='technology-support1-icon'>
							<StudyPlan />
						</div>
						<div className='class-support'>Study Plan Creation</div>
						<div className='class-support-desc'>Our study center generates customized study plans based on each student’s strengths, weaknesses, goals, and timelines.</div>
					</div>
					<div className='class-parent'>
						<div className='technology-support1-icon'>
							<Database />
						</div>
						<div className='class-support'>Proprietary Databases</div>
						<div className='class-support-desc'>We’ve crafted thousands of questions to ensure students never run out of practice.</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginDev;
