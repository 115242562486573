import React from 'react';
import Banner from '../Banner';
import Navbar from '../Navbar';
import Homepage from '../Homepage';
import RoadMap from '../RoadMap';
import QuickLinks from '../QuickLinks';
import Services from '../Services';
import Process from '../Process';
import International from '../International';
import FreeResource from '../FreeResource';
import PrivateTutoring from '../PrivateTutoring';
import CustomClass from '../CustomClass';
import Diagnostic from '../Diagnostic';
import CollegeConsulting from '../CollegeConsulting';
import ConsultationPlan from '../ConsultationPlan';
import OurTechnology from '../OurTechnology';
import SuccessStories from '../SuccessStories';
import Footer from '../Footer';

const Portal: React.FC = () => {
	return (
		<div id='portal'>
			<Banner />
			<Navbar />
			<div id='home'>
				<Homepage />
			</div>
			<div id='roadmap'>
				<RoadMap />
			</div>
			<div id='quicklinks'>
				<QuickLinks />
			</div>
			<div id='services'>
				<Services />
			</div>
			<div id='process'>
				<Process />
			</div>
			<div id='international'>
				<International />
			</div>
			<div id='free-resource'>
				<FreeResource />
			</div>
			<div id='private-tutoring'>
				<PrivateTutoring />
			</div>
			<div id='custom-class'>
				<CustomClass />
			</div>
			<div id='diagnostic'>
				<Diagnostic />
			</div>
			<div id='college-consulting'>
				<CollegeConsulting />
			</div>
			<div id='consultation-plan'>
				<ConsultationPlan />
			</div>
			<div id='technology'>
				<OurTechnology />
			</div>
			<div id='success-story'>
				<SuccessStories />
			</div>
			<div id='footer'>
				<Footer />
			</div>
		</div>
	);
};

export default Portal;
