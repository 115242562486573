import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import Button, { ButtonProps } from '@mui/material/Button';
import './ResetPassword.css';
import Logo from '../../assets/img/common/logo.png';
import LeftImg from '../../assets/img/common/left.png';
import CustomTextField from '../../components/CustomTextField';
import { common } from '../../assets/jss/messages';
import { useDispatch } from 'react-redux';
import { updateCommon } from '../../reduxstate/slices/portalSlice';
import apiQuery from '../../apicall/apiQuery';
import { validatePassword } from '../../shared';

const ResetPasswordPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	color: '#FFFF',
	height: '90vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	borderRadius: '2vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsRegular',
	fontSize: '1vmax',
	justifyContent: 'center',
	'&:hover': {
		background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	},
}));

const ResetPassword: React.FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let search = window.location.search;
	let params = new URLSearchParams(search);
	let token = params.get('token');
	const [firstLoad, setFirstLoad] = React.useState(true);

	useEffect(() => {
		if (firstLoad && token != null) {
			verifyUUID();
			setFirstLoad(false);
		}
		// eslint-disable-next-line
	}, []);

	const verifyUUID = () => {
		try {
			const body = {
				uuid: token,
			};
			apiQuery('auth/verifyuuid', {}, 'POST', body)
				.then((res: any) => {
					if (res.email) {
						setUserData({
							...userData,
							data: {
								...userData.data,
								email: res.email,
							},
						});
					}
				})
				.catch((err) => {
					dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.reset_fail } }));
				});
		} catch (error) {
			FailLogin(error);
		}
	};

	const resetPassword = () => {
		try {
			const body = {
				uuid: token,
				password: userData.data.password,
			};
			apiQuery('auth/resetPassword', {}, 'POST', body)
				.then((res: any) => {
					dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'success', message: common.reset_success } }));
					navigate('/login');
				})
				.catch((err) => {
					dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.reset_fail } }));
				});
		} catch (error) {
			FailLogin(error);
		}
	};

	const isEmptyOrSpaces = (value: string | null | undefined) => {
		return value === undefined || value === null || value === '';
	};

	const regex = {
		email: new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'),
		number: new RegExp('^[0-9]+$'),
		password: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
	};

	const [userData, setUserData] = React.useState({
		data: {
			email: '',
			password: '',
			confirmPassword: '',
		},
		error: {
			email: false,
			password: false,
			confirmPassword: false,
		},
		errorMessage: {
			required: common.required,
			email: common.required,
			password: common.required,
			confirmPassword: common.required,
		},
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const value = event.target.value;
		let isError = false;
		let errorMessage = common.required;

		if (isEmptyOrSpaces(value)) {
			isError = true;
		}

		if (!isError) {
			if (name === 'email') {
				const emailTest = regex.email.test(value);
				if (isEmptyOrSpaces(value) || !emailTest) {
					isError = true;
					errorMessage = isEmptyOrSpaces(value) ? common.required : common.invalid_email;
				}
			} else if (name === 'password') {
				const passwordError = validatePassword(value);
				if (passwordError) {
					isError = true;
					errorMessage = passwordError;
				}
			} else if (name === 'confirmPassword') {
				if (userData.data.password !== value) {
					isError = true;
					errorMessage = common.password_compare;
				}
			}
		}

		setUserData({
			...userData,
			data: {
				...userData.data,
				[name]: value,
			},
			error: {
				...userData.error,
				[name]: isError,
			},
			errorMessage: {
				...userData.errorMessage,
				[name]: errorMessage,
			},
		});
	};

	const validateAll = () => {
		let isValidData = true;
		let email = false;
		let password = false;
		let confirmPassword = false;
		let nomatch = false;
		let errorEmail = false;

		if (isEmptyOrSpaces(userData.data.email)) {
			email = true;
		} else if (!regex.email.test(userData.data.email)) {
			errorEmail = true;
			email = true;
		}

		let passwordErrorMessage = common.required;
		if (isEmptyOrSpaces(userData.data.password)) {
			password = true;
		} else {
			const passwordError = validatePassword(userData.data.password);
			if (passwordError) {
				password = true;
				passwordErrorMessage = passwordError;
			}
		}

		if (isEmptyOrSpaces(userData.data.confirmPassword)) {
			confirmPassword = true;
		} else if (userData.data.password !== userData.data.confirmPassword) {
			confirmPassword = true;
			nomatch = true;
		}

		if (email || password || confirmPassword) {
			isValidData = false;
		}

		setUserData({
			...userData,
			error: {
				...userData.error,
				email: email,
				password: password,
				confirmPassword: confirmPassword,
			},
			errorMessage: {
				...userData.errorMessage,
				email: errorEmail ? common.invalid_email : common.required,
				password: passwordErrorMessage,
				confirmPassword: nomatch ? common.password_compare : common.required,
			},
		});

		return isValidData;
	};

	const FailLogin = (error: any) => {
		dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.error_registration } }));
		console.error('Login failed:', error);
	};

	const handleResetPassword = async () => {
		try {
			const validate = validateAll();
			if (validate) {
				resetPassword();
			}
		} catch (error) {
			FailLogin(error);
		}
	};

	const handleLogoClick = () => {
		navigate('/');
	};

	return (
		<ResetPasswordPaper>
			<div className='resetpwd_header'>
				<div className='resetpwd_banner'></div>
				<img className='resetpwd_logo' alt='' src={Logo} onClick={handleLogoClick} />
			</div>
			<div className='resetpwd_content'>
				<div className='resetpwd_left'>
					<img className='left_image' alt='' src={LeftImg} />
				</div>
				<div className='resetpwd_right'>
					<Typography className='hello_text'>Hello!</Typography>
					<Typography className='sign_in_text'>Reset Your Password</Typography>
					<br />
					<Stack direction={'column'} justifyContent={'center'} spacing={2}>
						<CustomTextField
							label='EMAIL ID'
							name='email'
							type='text'
							required
							className='input_field'
							disabled={true}
							error={userData.error.email}
							helperText={userData.errorMessage.email}
							value={userData.data.email}
							onChange={handleChange}
						/>
						<CustomTextField
							label='PASSWORD'
							name='password'
							type='password'
							required
							className='input_field'
							error={userData.error.password}
							helperText={userData.errorMessage.password}
							value={userData.data.password}
							onChange={handleChange}
						/>
						<CustomTextField
							label='CONFIRM PASSWORD'
							name='confirmPassword'
							type='password'
							required
							className='input_field'
							error={userData.error.confirmPassword}
							helperText={userData.errorMessage.confirmPassword}
							value={userData.data.confirmPassword}
							onChange={handleChange}
						/>
					</Stack>
					<Stack className='botton_div' direction={'row'}>
						<CustomButton variant='contained' onClick={handleResetPassword}>
							RESET PASSWORD
						</CustomButton>
					</Stack>
				</div>
			</div>
		</ResetPasswordPaper>
	);
};

export default ResetPassword;
