import './Process.css';
import ProcessBg from '../../assets/img/process/background.png';
import { FunctionComponent } from 'react';

const Process: FunctionComponent = () => {
	return (
		<div className='process'>
			<div className='process-frame'>
				<div className='framework-for-improvement-parent'>
					<div className='framework-for-improvement'>Framework for Improvement</div>
					<div className='academics-test'>Academics - Test Prep - College Consulting – Career Advice</div>
				</div>
				<img className='process-1-icon' alt='' src={ProcessBg} />
			</div>
		</div>
	);
};

export default Process;
