import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper, { PaperProps } from '@mui/material/Paper';
import './Services.css';
import servicesScreen from '../../assets/img/services/services-new.png';
// import Logo from '../../assets/img/common/logo.png';
import { useNavigate } from 'react-router-dom';

const ServicePaper = styled(Paper)<PaperProps>(({ theme }) => ({
	margin: '1vh 0',
	color: '#FFFF',
	minHeight: '100vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
	display: 'flex',
	justifyContent: 'center',
}));

const Services: React.FC = () => {
	const navigate = useNavigate();
	const handleLinkClick = (url: string) => (event: React.MouseEvent<HTMLElement>) => {
		navigate(`/${url}`);
		setTimeout(() => {
			const targetId = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
			const targetSection = document.getElementById(targetId || '');
			if (targetSection) {
				window.scrollTo({
					top: targetSection.offsetTop,
					behavior: 'smooth',
				});
			}
		}, 200);
	};

	useEffect(() => {}, []);

	return (
		<ServicePaper>
			<img src={servicesScreen} alt='home-screen' className='service_bg_image' />
			<div className='multi-graph'>
				{/* <img src={Logo} alt='home-screen' className='service_logo' /> */}
				<div className='graph g0' data-name='OUR TECHNOLOGY' onClick={handleLinkClick('#technology')}>
					{''}
				</div>
				<div className='graph g1' data-name='COLLEGE CONSULTING' onClick={handleLinkClick('#college-consulting')}>
					{''}
				</div>
				<div className='graph g2' data-name='CUSTOM CLASSES' onClick={handleLinkClick('#custom-class')}>
					{''}
				</div>
				<div className='graph g3' data-name='PRIVATE TUTORING' onClick={handleLinkClick('#private-tutoring')}>
					{''}
				</div>
			</div>
			<div className='service_text text1' onClick={handleLinkClick('#private-tutoring')}>
				PRIVATE <br /> TUTORING
			</div>
			<div className='service_text text2' onClick={handleLinkClick('#custom-class')}>
				CUSTOM <br /> CLASSES
			</div>
			<div className='service_text text3' onClick={handleLinkClick('#college-consulting')}>
				COLLEGE <br /> CONSULTING
			</div>
			<div className='service_text text4' onClick={handleLinkClick('#technology')}>
				OUR <br /> TECHNOLOGY
			</div>
			{/* <div className='split-screen'>
				<div className='split-bottom-left'>
					<div className='content content-left'>
						<h2>Bottom Left</h2>
					</div>
					<div className='content content-remaining-left'>
						<h2>Remaining Left</h2>
					</div>
				</div>
				<div className='split-bottom-right'>
					<div className='content content-right'>
						<h2>Bottom Right</h2>
					</div>
					<div className='content content-remaining-right'>
						<h2>Remaining Right</h2>
					</div>
				</div>
			</div> */}
		</ServicePaper>
	);
};

export default Services;
