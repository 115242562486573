import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import './CustomDialog.css';
import { Stack, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useDispatch } from 'react-redux';
import { updateCommon } from '../../reduxstate/slices/portalSlice';

const CustomDialogStyle = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-paper': {
		backgroundColor: '#999999 !important',
		width: '100vw',
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
	},
	'& .MuiDialogContent-root': {
		padding: 0,
	},
	'& .MuiDialogActions-root': {
		padding: 0,
	},
}));

const ButtonStyled = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	borderRadius: '2vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsRegular',
	fontSize: '1vmax',
	justifyContent: 'center',
	width: '10vw',
	'&:hover': {
		background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	},
}));

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const CustomDialog: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [confirmDialog, setConfirmDialog] = React.useState(false);
	useEffect(() => {
		setConfirmDialog(true);
		// eslint-disable-next-line
	}, []);

	const handleClose = () => {
		setConfirmDialog(false);
		navigate(-1);
		dispatch(updateCommon({ redirectURL: '' }));
	};

	const handleJoinus = () => {
		setConfirmDialog(false);
		navigate('/joinus');
	};

	const handleLogin = () => {
		setConfirmDialog(false);
		navigate('/login');
	};

	return (
		<CustomDialogStyle open={confirmDialog || false} fullScreen TransitionComponent={Transition} keepMounted onClose={handleClose} className='confirm_body'>
			<Grid container justifyContent={'center'} alignItems={'center'} spacing={6} direction={'column'}>
				<Grid item>
					<Typography className='confirm_message'>{'Interested in enrolling to our courses and finding out more ?'}</Typography>
					<Typography className='confirm_sub_message'>{'Register / Login to enjoy a customized experience'}</Typography>
				</Grid>
				<Grid item xs>
					<Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={4}>
						<ButtonStyled variant='contained' onClick={() => handleJoinus()}>
							Register
						</ButtonStyled>
						<ButtonStyled variant='contained' onClick={() => handleLogin()}>
							Login
						</ButtonStyled>
						<ButtonStyled variant='contained' onClick={() => handleClose()}>
							Cancel
						</ButtonStyled>
					</Stack>
				</Grid>
			</Grid>
		</CustomDialogStyle>
	);
};

export default CustomDialog;
