import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography, Tooltip, IconButton, Stack, Link } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import Button, { ButtonProps } from '@mui/material/Button';
import './Signup.css';
import Logo from '../../assets/img/common/logo.png';
import LeftImg from '../../assets/img/common/left.png';
import { ReactComponent as MicrosoftIcon } from '../../assets/img/common/microsoft_og.svg';
import { ReactComponent as FbIcon } from '../../assets/img/common/facebook_og.svg';
import { ReactComponent as GoogleIcon } from '../../assets/img/common/google_og.svg';
import CustomTextField from '../../components/CustomTextField';
import { common } from '../../assets/jss/messages';
import { useDispatch } from 'react-redux';
import { updateCommon } from '../../reduxstate/slices/portalSlice';
import { updateUser } from '../../reduxstate/slices/userSlice';
import { IResolveParams, LoginSocialFacebook, LoginSocialGoogle, LoginSocialMicrosoft } from 'reactjs-social-login';
import apiQuery from '../../apicall/apiQuery';
import { Cookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { RootState } from '../../reduxstate/index';
import { getDomain, getCurrentUser, getUserData, validatePassword } from '../../shared';
import Processing from '../../components/Processing';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SignupPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	color: '#FFFF',
	height: '90vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	borderRadius: '2vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsRegular',
	fontSize: '1vmax',
	justifyContent: 'center',
	width: '10vw',
	'&:hover': {
		background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	},
}));

const Signup: React.FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const cookies = new Cookies();
	const { sysPref, workflow, redirectURL } = useSelector((state: RootState) => state.portal);
	const { userData: user } = useSelector((state: RootState) => state.user);
	const { GOOGLE_APP_ID, FB_APP_ID, MICROSOFT_APP_ID } = sysPref;
	const REDIRECT_URI = `${window.location.origin}${window.location.pathname}`;
	const [isProceesing, setIsProceesing] = React.useState(false);
	const [isSuccess, setSuccess] = React.useState(false);
	const [isError, setError] = React.useState(false);
	const [duplicateUser, setDuplicateUser] = React.useState(false);
	const [fromEnroll, setFromEnroll] = React.useState(false);
	const params = new URLSearchParams(window.location.search);
	const redirect_url: any = params.get('redirect_url');

	useEffect(() => {
		if (user) {
			if (!isEmptyOrSpaces(redirect_url)) window.location.replace(decodeURIComponent(redirect_url));
			else navigate('/');
		}
		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		if (workflow === 'enroll' && !isEmptyOrSpaces(redirectURL)) {
			setFromEnroll(true);
		} else {
			setFromEnroll(false);
		}
		// eslint-disable-next-line
	}, [workflow, redirectURL]);

	const isEmptyOrSpaces = (value: string | null | undefined) => {
		return value === undefined || value === null || value === '';
	};

	const regex = {
		email: new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'),
		number: new RegExp('^[0-9]+$'),
		password: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
	};

	const [userData, setUserData] = React.useState({
		data: {
			studentFirstName: '',
			studentLastName: '',
			email: '',
			password: '',
			confirmPassword: '',
		},
		error: {
			studentFirstName: false,
			studentLastName: false,
			email: false,
			password: false,
			confirmPassword: false,
		},
		errorMessage: {
			required: common.required,
			email: common.required,
			password: common.required,
			confirmPassword: common.required,
		},
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const value = event.target.value;
		let isError = false;
		let errorMessage = common.required;

		if (isEmptyOrSpaces(value)) {
			isError = true;
		}
		if (!isError) {
			if (name === 'email') {
				const emailTest = regex.email.test(value);
				if (isEmptyOrSpaces(value) || !emailTest) {
					isError = true;
					errorMessage = isEmptyOrSpaces(value) ? common.required : common.invalid_email;
				}
			} else if (name === 'password') {
				const passwordError = validatePassword(value);
				if (passwordError) {
					isError = true;
					errorMessage = passwordError;
				}
			} else if (name === 'confirmPassword') {
				if (userData.data.password !== value) {
					isError = true;
					errorMessage = common.password_compare;
				}
			}
		}

		setDuplicateUser(false);
		setError(false);

		setUserData({
			...userData,
			data: {
				...userData.data,
				[name]: value,
			},
			error: {
				...userData.error,
				[name]: isError,
			},
			errorMessage: {
				...userData.errorMessage,
				[name]: errorMessage,
			},
		});
	};

	const validateAll = () => {
		let isValidData = true;
		let studentFirstName = false;
		let studentLastName = false;
		let email = false;
		let password = false;
		let confirmPassword = false;
		let nomatch = false;
		let errorEmail = false;

		if (isEmptyOrSpaces(userData.data.studentFirstName)) {
			studentFirstName = true;
		}
		if (isEmptyOrSpaces(userData.data.studentLastName)) {
			studentLastName = true;
		}
		if (isEmptyOrSpaces(userData.data.email)) {
			email = true;
		} else if (!regex.email.test(userData.data.email)) {
			errorEmail = true;
			email = true;
		}
		let passwordErrorMessage = common.required;
		if (isEmptyOrSpaces(userData.data.password)) {
			password = true;
		} else {
			const passwordError = validatePassword(userData.data.password);
			if (passwordError) {
				password = true;
				passwordErrorMessage = passwordError;
			}
		}

		if (isEmptyOrSpaces(userData.data.confirmPassword)) {
			confirmPassword = true;
		} else if (userData.data.password !== userData.data.confirmPassword) {
			confirmPassword = true;
			nomatch = true;
		}

		if (studentFirstName || studentLastName || email || password || confirmPassword) {
			isValidData = false;
		}

		setUserData({
			...userData,
			error: {
				...userData.error,
				studentFirstName: studentFirstName,
				studentLastName: studentLastName,
				email: email,
				password: password,
				confirmPassword: confirmPassword,
			},
			errorMessage: {
				...userData.errorMessage,
				email: errorEmail ? common.invalid_email : common.required,
				password: passwordErrorMessage,
				confirmPassword: nomatch ? common.password_compare : common.required,
			},
		});

		setDuplicateUser(false);
		setError(false);
		return isValidData;
	};

	const successLogin = async (data: any, onload?: boolean) => {
		try {
			const domain = getDomain();
			cookies.remove('access_token');
			cookies.remove('refresh_token');
			cookies.set('access_token', data.access_token, domain);
			cookies.set('refresh_token', data.refresh_token, domain);

			const user = await getCurrentUser();
			if (user) {
				dispatch(updateUser({ userData: user }));
				getUserDetails();
				setIsProceesing(false);
			} else {
				if (!onload) FailLogin('Error while getting user data');
			}
		} catch (error) {
			if (!onload) FailLogin(error);
		}
	};

	const getUserDetails = async () => {
		const regUser = await getUserData();
		if (regUser) {
			dispatch(updateUser({ registerData: regUser }));
			if (!isEmptyOrSpaces(redirect_url)) {
				window.location.replace(decodeURIComponent(redirect_url));
			} else if ((workflow === 'diagnostic' || workflow === 'fulllength') && !isEmptyOrSpaces(redirectURL)) {
				window.location.replace(redirectURL);
			} else if (workflow === 'enroll' && !isEmptyOrSpaces(redirectURL)) {
				const enroll_course_id: string | null = sessionStorage.getItem('enroll_course_id');
				const session_redirect_url: string | null = sessionStorage.getItem('redirect_url');
				let isEnrolled = false;
				if (regUser && regUser?.enrolledCourses && regUser?.enrolledCourses?.includes(Number(enroll_course_id))) {
					isEnrolled = true;
				}
				if (isEnrolled) {
					sessionStorage.removeItem('redirect_url');
					sessionStorage.removeItem('enroll_course_id');
					dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'info', message: common.duplicate_enroll } }));
					navigate(session_redirect_url ? session_redirect_url : '/');
				} else {
					window.location.replace(redirectURL);
				}
			} else {
				navigate('/');
			}
		} else {
			if (!isEmptyOrSpaces(redirect_url)) navigate(`/registration?redirect_url=${redirect_url}`);
			else navigate('/registration');

			// if (!isEmptyOrSpaces(redirect_url)) window.location.replace(decodeURIComponent(redirect_url));
			// else if (workflow === 'diagnostic' && !isEmptyOrSpaces(redirectURL)) window.location.replace(redirectURL);
			// else navigate(workflow === 'enroll' || workflow === 'fulllength' ? '/registration' : '/');
		}
	};

	const FailLogin = (error: any) => {
		if (error?.reason?.statusCode === 409) {
			// dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'info', message: common.duplicate_user } }));
			setDuplicateUser(true);
		} else {
			// dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.error_registration } }));
			setError(true);
		}
		console.error('Login failed:', error);
		setIsProceesing(false);
	};

	const checkLogin = (email: string, password: string, loginType?: string, accessToken?: string) => {
		try {
			const body = {
				email: email,
				password: password,
				loginType: loginType,
				accessToken: accessToken,
			};
			apiQuery('auth/login', {}, 'POST', body)
				.then((result) => {
					successLogin(result);
				})
				.catch((error) => {
					FailLogin(error);
				});
		} catch (error) {
			FailLogin(error);
		}
	};

	const sucessSignup = (user: any) => {
		const auth = ['GOOGLE', 'FACEBOOK', 'MICROSOFT', 'APPLE'];
		if (auth.includes(user.loginType.toUpperCase())) {
			checkLogin(user.email, user.loginType, user.loginType, user.accessToken);
		} else {
			setIsProceesing(false);
			// dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'success', message: common.success_registration } }));
			// navigate('/login');
			setSuccess(true);
		}
	};

	const createUser = (user: any) => {
		setIsProceesing(true);
		try {
			apiQuery('register/user', {}, 'POST', user)
				.then((result: any) => {
					sucessSignup(user);
				})
				.catch((error: any) => {
					const auth = ['GOOGLE', 'FACEBOOK', 'MICROSOFT', 'APPLE'];
					if (error?.reason?.statusCode === 409) {
						if (auth.includes(user.loginType.toUpperCase())) checkLogin(user.email, user.loginType, user.loginType, user.accessToken);
						else {
							setDuplicateUser(true);
							setIsProceesing(false);
						}
					} else {
						FailLogin(error);
					}
				});
		} catch (error) {
			FailLogin(error);
		}
	};

	const handleRegularLogin = async () => {
		try {
			const validate = validateAll();
			if (validate) {
				const newUser = {
					email: userData.data.email,
					password: userData.data.password,
					firstName: userData.data.studentFirstName,
					lastName: userData.data.studentLastName,
					loginType: 'REGULAR',
					validated: false,
				};
				createUser(newUser);
			}
		} catch (error) {
			FailLogin(error);
		}
	};

	const handleGoogleLoginSuccess = (data: any) => {
		const newUser = {
			email: data.email,
			password: 'GOOGLE',
			firstName: data.given_name,
			lastName: data.family_name,
			loginType: 'GOOGLE',
			validated: true,
			accessToken: data.access_token,
		};
		createUser(newUser);
	};

	const handleGoogleLoginFail = (error: any) => {
		FailLogin(error);
	};

	const handleFacebookLoginSuccess = (data: any) => {
		const newUser = {
			email: data.email,
			password: 'FACEBOOK',
			firstName: data.first_name,
			lastName: data.last_name,
			loginType: 'FACEBOOK',
			validated: true,
			accessToken: data.accessToken,
		};
		createUser(newUser);
	};

	const handleFacebookLoginFail = (error: any) => {
		FailLogin(error);
	};

	const handleMicrosoftLoginSuccess = (data: any) => {
		const newUser = {
			email: data.mail,
			password: 'MICROSOFT',
			firstName: data.givenName,
			lastName: data.surname,
			loginType: 'MICROSOFT',
			validated: true,
			accessToken: data.access_token,
		};
		createUser(newUser);
	};

	const handleMicrosoftLoginFail = (error: any) => {
		FailLogin(error);
	};

	const handleLogin = () => {
		navigate('/login');
	};

	const handleForgotPassword = () => {
		navigate('/forgotPassword');
	};

	const handleLogoClick = () => {
		dispatch(updateCommon({ redirectURL: '' }));
		dispatch(updateCommon({ workflow: '' }));
		navigate('/');
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			handleRegularLogin();
		}
	};

	return (
		<SignupPaper>
			<Processing isProceesing={isProceesing} />
			<div className='signup_header'>
				<div className='signup_banner'></div>
				<img className='signup_logo' alt='' src={Logo} onClick={handleLogoClick} />
			</div>
			<div className='signup_content'>
				<div className='signup_left'>
					<img className='left_image' alt='' src={LeftImg} />
				</div>
				{isSuccess ? (
					<div className='signup_right success_signup'>
						<Typography className='hello_text'>Welcome!</Typography>
						<CheckCircleIcon className='sign_up_success_icon' />
						<Typography className='sign_up_text_1'>{common.success_registration_text_1}</Typography>
						<Typography className='sign_up_text_2'>
							<mark>{common.success_registration_text_2}</mark>
						</Typography>
						<div className='sign_up_text_2_line' />
						<Stack className='botton_div' direction={'row'} spacing={4}>
							<CustomButton variant='contained' onClick={() => navigate('/')}>
								Go to Home
							</CustomButton>
							<CustomButton variant='contained' onClick={() => navigate('/login')}>
								Go to Login
							</CustomButton>
						</Stack>
					</div>
				) : (
					<div className='signup_right'>
						<Typography className='hello_text'>Hello!</Typography>
						<Typography className='sign_in_text'>Create Your Account</Typography>
						<Stack className={fromEnroll ? 'social_icon_list_small' : 'social_icon_list'} direction={'row'}>
							<LoginSocialGoogle
								client_id={process.env.REACT_APP_GOOGLE_APP_ID || GOOGLE_APP_ID}
								scope='openid profile email'
								onResolve={({ provider, data }: IResolveParams) => {
									handleGoogleLoginSuccess(data);
								}}
								onReject={(err) => {
									handleGoogleLoginFail(err);
								}}
							>
								<Tooltip title='Register with Google'>
									<IconButton edge='end' color='inherit' aria-controls='menu' aria-haspopup='true'>
										<GoogleIcon className='social_icon' />
									</IconButton>
								</Tooltip>
							</LoginSocialGoogle>

							<LoginSocialFacebook
								appId={process.env.REACT_APP_FB_APP_ID || FB_APP_ID}
								onResolve={({ provider, data }: IResolveParams) => {
									handleFacebookLoginSuccess(data);
								}}
								onReject={(err) => {
									handleFacebookLoginFail(err);
								}}
							>
								<Tooltip title='Register with Facebook'>
									<IconButton edge='end' color='inherit' aria-controls='menu' aria-haspopup='true'>
										<FbIcon className='social_icon' />
									</IconButton>
								</Tooltip>
							</LoginSocialFacebook>

							<LoginSocialMicrosoft
								client_id={process.env.REACT_APP_MICROSOFT_APP_ID || MICROSOFT_APP_ID}
								redirect_uri={REDIRECT_URI}
								onResolve={({ provider, data }: IResolveParams) => {
									handleMicrosoftLoginSuccess(data);
								}}
								onReject={(err) => {
									handleMicrosoftLoginFail(err);
								}}
							>
								<Tooltip title='Register with Microsoft'>
									<IconButton edge='end' color='inherit' aria-controls='menu' aria-haspopup='true'>
										<MicrosoftIcon className='social_icon' />
									</IconButton>
								</Tooltip>
							</LoginSocialMicrosoft>
						</Stack>
						{fromEnroll && (
							<Stack spacing={0} direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ margin: '0% 5%' }}>
								<Typography className='sign_up_text_3'> Or </Typography>
								<Typography className='sign_up_text_3'> Complete the information below but please note: </Typography>
								<Typography className='sign_up_text_3'> If you do so, you will be required to restart the course selection process </Typography>
								<br />
							</Stack>
						)}
						<Stack direction={'column'} justifyContent={'center'} spacing={2}>
							<CustomTextField
								label='STUDENT FIRST NAME'
								name='studentFirstName'
								type='text'
								required
								className='input_field'
								error={userData.error.studentFirstName}
								helperText={userData.errorMessage.required}
								value={userData.data.studentFirstName}
								onChange={handleChange}
								onKeyDown={handleKeyDown}
							/>
							<CustomTextField
								label='STUDENT LAST NAME'
								name='studentLastName'
								type='text'
								required
								className='input_field'
								error={userData.error.studentLastName}
								helperText={userData.errorMessage.required}
								value={userData.data.studentLastName}
								onChange={handleChange}
								onKeyDown={handleKeyDown}
							/>
							<CustomTextField
								label='EMAIL ID'
								name='email'
								type='text'
								required
								className='input_field'
								error={userData.error.email}
								helperText={userData.errorMessage.email}
								value={userData.data.email}
								onChange={handleChange}
								onKeyDown={handleKeyDown}
							/>
							<CustomTextField
								label='PASSWORD'
								name='password'
								type='password'
								required
								className='input_field'
								error={userData.error.password}
								helperText={userData.errorMessage.password}
								value={userData.data.password}
								onChange={handleChange}
								onKeyDown={handleKeyDown}
							/>
							<CustomTextField
								label='CONFIRM PASSWORD'
								name='confirmPassword'
								type='password'
								required
								className='input_field'
								error={userData.error.confirmPassword}
								helperText={userData.errorMessage.confirmPassword}
								value={userData.data.confirmPassword}
								onChange={handleChange}
								onKeyDown={handleKeyDown}
							/>
						</Stack>
						<Stack className='botton_div' direction={'row'}>
							<CustomButton variant='contained' onClick={handleRegularLogin}>
								Register
							</CustomButton>
						</Stack>
						{duplicateUser && (
							<Stack direction={'column'} spacing={1}>
								<Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
									<Typography className='signup_duplicate_user'>Oops…This email is already registered.</Typography>
									<Link component='button' underline='hover' className='signup_duplicate_user' onClick={handleLogin}>
										Click here to Login.
									</Link>
								</Stack>
								<Link component='button' underline='hover' className='signup_duplicate_user' onClick={handleForgotPassword}>
									If you don't remember your password, click here.
								</Link>
							</Stack>
						)}
						{isError && (
							<Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
								<Typography className='signup_duplicate_user'>
									<b>Error:</b> {common.error_registration}
								</Typography>
							</Stack>
						)}
					</div>
				)}
			</div>
		</SignupPaper>
	);
};

export default Signup;
