import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, MenuList, IconButton, Menu, MenuItem, Grid, Typography, Avatar } from '@mui/material';
import { PhoneAndroidOutlined } from '@mui/icons-material';
import Logo from '../../assets/img/common/logo.png';
import './NavbarDashboard.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxstate/index';
import { updateUser } from '../../reduxstate/slices/userSlice';
import { Cookies } from 'react-cookie';
import { getDomain, getHostName } from '../../shared';
import { NavLink } from 'react-router-dom';

const StyledMenu = styled(Menu)(({ theme }) => ({
	'& .MuiMenu-paper': {
		backgroundColor: '#0146C7 !important',
	},
}));

let cmsUrl: string | null = null;

const NavigationBarDashboard: React.FC = () => {
	const navigate = useNavigate();
	const user = useSelector((state: RootState) => state.user.userData);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [anchorReportEl, setAnchorReportEl] = useState<null | HTMLElement>(null);
	const dispatch = useDispatch();
	const cookies = new Cookies();
	const { mobile } = useSelector((state: RootState) => state.portal);
	const [greeting, setGreeting] = useState('Good Morning');
	const reportopen = Boolean(anchorReportEl);
	const [admin, setAdmin] = useState(false);
	useEffect(() => {
		initGet();
		const today = new Date();
		const curHr = today.getHours();
		if (curHr < 12) {
			setGreeting('Good Morning');
		} else if (curHr < 18) {
			setGreeting('Good Afternoon');
		} else {
			setGreeting('Good Evening');
		}
		// eslint-disable-next-line
	}, [user]);

	const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const parseJwt = (token: any) => {
		try {
			const base64Url = token.split('.')[1];
			const base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
			const jsonPayload = decodeURIComponent(
				window
					.atob(base64)
					.split('')
					.map(function (c) {
						return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join('')
			);
			return JSON.parse(jsonPayload);
		} catch (error) {}
		return null;
	};

	const redirectLogin = async () => {
		const cookies = new Cookies();
		const currentUrl = window.location.href;
		const redirectURI = await getMainUrl();
		const redirect_url = `${redirectURI}/login?redirect_url=${encodeURIComponent(currentUrl)}`;
		const domain = getDomain();
		cookies.remove('access_token');
		cookies.remove('refresh_token');
		cookies.remove('access_token', domain);
		cookies.remove('refresh_token', domain);
		window.location?.replace(redirect_url);
	};

	const retryCallApi = async (url: any, options: any): Promise<Response> => {
		const isSucess = await getRefreshToken();
		if (isSucess) {
			const response = await fetch(getApiUrl(url), options);
			return response;
		} else {
			redirectLogin();
			return Promise.reject('Authorization failed');
		}
	};

	const getRefreshToken = async () => {
		try {
			const cookies = new Cookies();
			const refresh_token = cookies.get('refresh_token');
			const access_token = cookies.get('access_token');
			const token = parseJwt(access_token);
			if (refresh_token && token.email) {
				const options: any = {
					method: 'POST',
					body: JSON.stringify({
						email: token.email,
						refreshtoken: refresh_token,
					}),
					headers: {
						'Content-Type': 'application/json',
					},
				};

				const response = await fetch(getApiUrl(`auth/refreshtoken`), options);
				if (response?.status === 200 || response?.status === 201) {
					const result = await response.json();
					const domain = getDomain();
					cookies.remove('access_token');
					cookies.remove('refresh_token');
					cookies.set('access_token', result.access_token, domain);
					cookies.set('refresh_token', result.refresh_token, domain);
					return true;
				}
			}
		} catch (error) {
			console.log('Refresh Token Error: ', error);
		}
		return false;
	};

	const getApiUrl = (url: string) => {
		const loc = window.location;
		const mode: string = loc.hostname === 'localhost' ? 'development' : 'production';
		let prodURL = `api.${getHostName()}`;
		if (mode === 'production') {
			const hostList = prodURL?.split('.');
			const hostListNew = hostList.filter((item) => item !== 'dashboard');
			prodURL = hostListNew.join('.');
		}
		let apiUrl = mode === 'development' ? 'localhost:3001/api' : prodURL;
		const auth = ['auth', 'users', 'register'];
		const register = ['registration', 'payment', 'studentdetails'];
		if (auth.some((v: any) => url.includes(v))) {
			apiUrl = mode === 'development' ? 'localhost:3001/api' : `${prodURL}/authentication/api`;
		} else if (register.some((v: any) => url.includes(v))) {
			apiUrl = mode === 'development' ? 'localhost:3002/api' : `${prodURL}/registration/api`;
		}
		return `${loc.protocol}//${apiUrl}/${url}`;
	};

	const callApi = async (url: any, method?: string, body?: any): Promise<Response> => {
		const cookies = new Cookies();
		const access_token = cookies.get('access_token');
		const options: any = {
			method: method || 'GET',
			headers: {
				Authorization: 'Bearer ' + access_token,
				'Content-Type': 'application/json',
			},
		};
		if (options.method === 'POST' || options.method === 'PUT') {
			options['body'] = body ? JSON.stringify(body) : '';
		}
		try {
			const response = await fetch(getApiUrl(url), options);
			if (response.status === 403 || response.status === 401) {
				const retryResponse = await retryCallApi(url, options);
				return retryResponse;
			}
			return response;
		} catch (error: any) {
			if (error?.response?.status === 403 || error?.response?.status === 401) {
				const retryResponse = await retryCallApi(url, options);
				return retryResponse;
			}
			return Promise.reject(error);
		}
	};

	const checkAdminUser = async (email: any) => {
		try {
			const contentAPI = await getCmsURL();
			if (contentAPI) {
				const filter = `filters[email][$eqi]=${email}`;
				const url = `${contentAPI}/api/administrators?${filter}`;
				const options: any = {
					method: 'GET',
				};
				const response = await fetch(url, options);
				if (response.status === 200) {
					const data = await response.json();
					return data?.data?.length > 0 || false;
				}
			}
		} catch (error) {
			console.error(error);
		}
		return false;
	};

	const getCmsURL = async () => {
		if (cmsUrl) {
			return cmsUrl;
		}
		try {
			const response = await callApi(`auth/getcmsurl`);
			if (response.status === 200) {
				const data = await response.json();
				cmsUrl = data.contentUrl;
				return data.contentUrl;
			} else {
				console.error('Failed to get cms url');
			}
		} catch (error) {
			console.error('Failed to get cms url');
		}
		return null;
	};

	const initGet = async () => {
		if (user) {
			const adminData = await checkAdminUser(user.email);
			setAdmin(adminData);
		}
	};

	const handleManage = () => {
		let redirect_url = `${window.location.protocol}//dashboard.${getHostName()}`;
		// let redirect_url = `http://localhost:3006`;
		window.location.replace(redirect_url);
	};

	const handleProfile = () => {
		let redirect_url = `${window.location.protocol}//${getHostName(true)}/registration`;
		// let redirect_url = `http://localhost:3000/registration`;
		window.location.replace(redirect_url);
	};

	const getMainUrl = async () => {
		try {
			const currentUrl = window.location.href;
			const isLocal = currentUrl.includes('localhost');
			const hostName: any = getHostName(true);
			const hostList = hostName?.split('.');
			const hostListNew = hostList.filter((item: any) => item !== 'dashboard');
			const host = hostListNew.join('.');
			const prodURL = `${window.location.protocol}//${host}`;
			const mainUrl = isLocal ? 'http://localhost:3000' : prodURL;
			return mainUrl;
		} catch (error) {}
		return null;
	};

	const handleLogout = () => {
		dispatch(updateUser({ userData: null, registerData: null }));
		const domain = getDomain();
		cookies.remove('access_token');
		cookies.remove('refresh_token');
		cookies.remove('access_token', domain);
		cookies.remove('refresh_token', domain);
		sessionStorage.clear();
		setAnchorEl(null);
	};

	const handleReportMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorReportEl(event.currentTarget);
	};
	const handleReportMenuClose = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorReportEl(null);
	};
	const handleUserReportMenuClose = (event: React.MouseEvent<HTMLElement>) => {
		let redirect_url = `${window.location.protocol}//dashboard.${getHostName()}/users`;
		// let redirect_url = `http://localhost:3006`;
		window.location.replace(redirect_url);
		setAnchorReportEl(null);
	};
	const handleMenuCourseClose = () => {
		let redirect_url = `${window.location.protocol}//dashboard.${getHostName()}/courses`;
		// let redirect_url = `http://localhost:3006`;
		window.location.replace(redirect_url);
		setAnchorEl(null);
	};
	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const formatPhoneNumber = (phone: string) => {
		var cleaned = ('' + phone).replace(/\D/g, '');
		var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			var intlCode = match[1] ? '+1 ' : '';
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
		}
		return null;
	};

	const handleLinkClick = (url: string) => (event: React.MouseEvent<HTMLElement>) => {
		navigate(`/${url}`);
		setTimeout(() => {
			const targetId = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
			const targetSection = document.getElementById(targetId || '');
			if (targetSection) {
				window.scrollTo({
					top: targetSection.offsetTop,
					behavior: 'smooth',
				});
			}
		}, 200);
	};

	return (
		<AppBar position='sticky' className='appbar'>
			<Toolbar className='toolbar'>
				<Grid container direction={'row'} alignItems={'center'}>
					<Grid item>
						<div onClick={handleLinkClick('#portal')}>
							<img src={Logo} alt='Logo' className='logo_img' />
						</div>
					</Grid>
					<Grid item>
						<div className='frame-group'>
							<div className='hello-john-parent'>
								<div className='hello-john'>
									<span className='hello-john-txt-container'>
										<b>Hello,</b>
										<span> {user && user.firstName}</span>
									</span>
								</div>
								<div className='good-morning'>{greeting}</div>
							</div>
						</div>
					</Grid>
					<Grid item xs>
						<MenuList style={{ display: 'flex', justifyContent: 'center' }}>
							<MenuItem>
								<div className='menu_item' onClick={handleManage}>
									My DASHBOARD
								</div>
							</MenuItem>
							{admin && (
								<Grid item>
									<div>
										<MenuItem onClick={handleReportMenuClick}>
											<div className='menu_item'>REPORTS</div>
										</MenuItem>
										<Menu
											id='demo-positioned-menu'
											aria-labelledby='demo-positioned-button'
											anchorEl={anchorReportEl}
											open={reportopen}
											onClose={handleReportMenuClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'left',
											}}
										>
											<NavLink
												to={`/users`}
												style={{
													color: 'inherit',
													textDecoration: 'inherit',
												}}
											>
												<MenuItem onClick={handleUserReportMenuClose}>User Report</MenuItem>
											</NavLink>
											<NavLink
												to={`/courses`}
												style={{
													color: 'inherit',
													textDecoration: 'inherit',
												}}
											>
												<MenuItem onClick={handleMenuCourseClose}>Course Report</MenuItem>
											</NavLink>
										</Menu>
									</div>
								</Grid>
							)}
						</MenuList>
					</Grid>
					<Grid item>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{user === null ? (
								<IconButton className='icon_btn background_color' edge='end' color='inherit' aria-controls='menu' aria-haspopup='true'></IconButton>
							) : (
								<IconButton className='icon_btn background_color' edge='end' color='inherit' aria-controls='menu' aria-haspopup='true' onClick={handleProfileClick}>
									<Avatar className='avator_icon'>{`${user?.firstName?.toUpperCase()?.slice(0, 1)}${user?.lastName?.toUpperCase()?.slice(0, 1)}`}</Avatar>
								</IconButton>
							)}
							{/* <IconButton className='icon_btn shopping_icon' edge='end' color='inherit' aria-controls='menu' aria-haspopup='true'>
							<ShoppingCartOutlined className='icon' />
						</IconButton> */}
							<a href={`tel:${mobile}`} className='phone_link'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<IconButton className='icon_btn background_color phone_icon' edge='end' color='inherit' aria-controls='menu' aria-haspopup='true'>
										<PhoneAndroidOutlined className='icon' />
									</IconButton>
									<Typography className='nav_typography background_color'>{formatPhoneNumber(mobile)}</Typography>
								</div>
							</a>
						</div>
					</Grid>
				</Grid>
				<StyledMenu
					id='menu'
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					{/* <MenuItem className='popup_menu_item' onClick={handleLinkClick('#portal')}>
						Portal
					</MenuItem> */}
					<MenuItem className='popup_menu_item' onClick={handleProfile}>
						My Profile
					</MenuItem>
					<MenuItem className='popup_menu_item' onClick={handleLogout}>
						Logout
					</MenuItem>
				</StyledMenu>
			</Toolbar>
		</AppBar>
	);
};

export default NavigationBarDashboard;
