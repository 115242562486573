import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper, { PaperProps } from '@mui/material/Paper';
import './DiagnosticTest.css';
import { Stack, Typography } from '@mui/material';
import Navbar from '../Navbar';
import { PublicQuiz } from '@alpha-prep/dashboard-diagnostic';
import { store } from '@alpha-prep/redux-state';
import { MathJaxContext } from 'better-react-mathjax';
import { Provider } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateCommon } from '../../reduxstate/slices/portalSlice';
import { getHostName } from '../../shared';

const DiagnosticTestPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	color: '#FFFF',
	height: '98vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const DiagnosticTest: React.FC = () => {
	const [firstLoad, setFirstLoad] = React.useState(true);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (firstLoad) {
			setFirstLoad(false);
		}
		// eslint-disable-next-line
	}, []);

	const handleLoginClick = () => {
		let redirect_url = `${window.location.protocol}//dashboard.${getHostName()}`;
		dispatch(updateCommon({ redirectURL: redirect_url }));
		dispatch(updateCommon({ workflow: 'diagnostic' }));
		navigate('/login');
	};

	const config = {
		loader: { load: ['input/asciimath'] },
	};

	return (
		<DiagnosticTestPaper>
			<Navbar />
			<div className='test_body'>
				<Stack className='test_header_parent' justifyContent={'center'} alignItems={'center'}>
					<Stack justifyContent={'center'} alignItems={'center'} direction={'column'} spacing={1}>
						<Typography className='test_header'>This is a Sample Question From a Diagnostic</Typography>
						<div onClick={handleLoginClick} className='test_header_right'>
							Login or Register to Take a Complete Diagnostic
						</div>
					</Stack>
					<MathJaxContext config={config}>
						<Provider store={store}>
							<PublicQuiz portal={true} />
						</Provider>
					</MathJaxContext>
				</Stack>
			</div>
		</DiagnosticTestPaper>
	);
};

export default DiagnosticTest;
