import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxstate/index';
import { updateCommon } from '../../reduxstate/slices/portalSlice';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

interface CustomSnackBarProps {}
const CustomSnackbar: React.FC<CustomSnackBarProps> = (props) => {
	const dispatch = useDispatch();
	const snackbarDetail = useSelector((state: RootState) => state.portal.snackbarDetail);
	const handleClose = () => {
		dispatch(updateCommon({ snackbarDetail: null }));
	};

	return (
		<Snackbar open={snackbarDetail?.open || false} autoHideDuration={snackbarDetail?.duration || 6000} onClose={handleClose}>
			<Alert onClose={handleClose} severity={snackbarDetail?.severity || 'success'} sx={{ width: '100%' }}>
				{snackbarDetail?.message || ''}
			</Alert>
		</Snackbar>
	);
};

export default CustomSnackbar;
