import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { Search, EmailOutlined } from '@mui/icons-material';
import './Homepage.css';
import homeScreen from '../../assets/img/homepage/tutor.png';
// import { ReactComponent as MouseIcon } from '../../assets/img/homepage/mouse.svg';
import { ReactComponent as DiagnosticIcon } from '../../assets/img/homepage/diagnostic.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../reduxstate/index';

const HomeButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	backgroundColor: '#0146C7',
	borderRadius: '1vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsSemiBold',
	fontSize: '0.9vmax',
	width: '15vmax',
	'&:hover': {
		backgroundColor: '#0146C7',
	},
}));

const Homepage: React.FC = () => {
	const { emailId } = useSelector((state: RootState) => state.portal);
	const navigate = useNavigate();
	const handleLinkClick = (url: string) => (event: React.MouseEvent<HTMLElement>) => {
		navigate(`/${url}`);
		setTimeout(() => {
			const targetId = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
			const targetSection = document.getElementById(targetId || '');
			if (targetSection) {
				window.scrollTo({
					top: targetSection.offsetTop,
					behavior: 'smooth',
				});
			}
		}, 200);
	};

	const handleContactClick = (emailId: any) => {
		const emailLink = `mailto:${emailId}`;
		window.location.href = emailLink;
	};

	useEffect(() => { }, []);

	return (
		<Grid container justifyContent={'space-between'} alignItems={'center'}>
			<Grid item xs={7}>
				<Grid container alignItems={'center'} direction={'column'}>
					<Grid item>
						<Typography className='home_header'>AlphaPrep</Typography>
					</Grid>
					<Grid item>
						<Typography className='home_summary'>Empowering Students By Combining Personalized Tutoring and Mentoring with Cutting-Edge Technology </Typography>
					</Grid>
					<Grid item>
						<Typography className='home_description'>
							Find out why our students' outcomes are the best – bar none. Take a free Digital SAT Diagnostic and speak to an AlphaPrep Tutor and Counselor.
						</Typography>
					</Grid>
					<Grid item>
						<Grid container direction={'row'} justifyContent={'center'} spacing={4} className='home_button'>
							<Grid item>
								<HomeButton variant='contained' startIcon={<DiagnosticIcon className='all_icon' />} onClick={handleLinkClick('#diagnostic')}>
									Take a Diagnostic
								</HomeButton>
							</Grid>
							<Grid item>
								<HomeButton variant='contained' startIcon={<Search />} onClick={handleLinkClick('#custom-class')}>
									Take a Class
								</HomeButton>
							</Grid>
							<Grid item>
								<HomeButton variant='contained' startIcon={<EmailOutlined />} onClick={() => handleContactClick(emailId)}>
									Contact Us
								</HomeButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={5}>
				<img src={homeScreen} alt='home-screen' className='home_right_bg_image' />
			</Grid>
		</Grid>
	);
};

export default Homepage;
