import React, {useEffect } from 'react';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper, { PaperProps } from '@mui/material/Paper';
import Button, { ButtonProps } from '@mui/material/Button';
// import Slide, { SlideProps } from '@mui/material/Slide';
import './QuickLinks.css';
import roadMapBg from '../../assets/img/roadmap/roadmap_bg.png';
import { ReactComponent as FreeResourceIcon } from '../../assets/img/roadmap/free_resourses.svg';
import { ReactComponent as PrivateTutorIcon } from '../../assets/img/roadmap/private_tutoring.svg';
import { ReactComponent as TakeClassIcon } from '../../assets/img/roadmap/take_a_class.svg';
import { ReactComponent as CollegeConsultingIcon } from '../../assets/img/roadmap/college_counsulting.svg';
import roadPath from '../../assets/img/roadmap/path.png';
import { useNavigate } from 'react-router-dom';

const LeftButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	backgroundColor: '#0146C6',
	borderRadius: '1vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsLight',
	fontSize: '1.25vmax',
	justifyContent: 'left',
	padding: '0.5vmax',
	paddingRight: '1vmax !important',
	width: '100%',
	'&:hover': {
		backgroundColor: '#0146C6',
	},
}));

const RoadMapPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	color: '#FFFF',
	height: '100vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',	
}));

const QuickLinks: React.FC = () => {
	const navigate = useNavigate();

	const handleLinkClick = (url: string) => (event: React.MouseEvent<HTMLElement>) => {
		navigate(`/${url}`);
		setTimeout(() => {
			const targetId = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
			const targetSection = document.getElementById(targetId || '');
			if (targetSection) {
				window.scrollTo({
					top: targetSection.offsetTop,
					behavior: 'smooth',
				});
			}
		}, 200);
	};

	useEffect(() => { }, []);

	return (
		<RoadMapPaper>
			<img src={roadMapBg} alt='home-screen' className='road_bg_image' />
			<div className="quicklinksMain">
				<div className='imagePart'>
					<img src={roadPath} alt='home-screen' className='road_path_flow_image' />
				</div>
				<div>
					<Stack direction='column' justifyContent='space-evenly' alignItems='start' spacing={5}>
						<LeftButton variant='contained' startIcon={<PrivateTutorIcon className='roadmap_icon' />} onClick={handleLinkClick('#private-tutoring')}>
							Private Tutoring
						</LeftButton>
						<LeftButton variant='contained' startIcon={<TakeClassIcon className='roadmap_icon' />} onClick={handleLinkClick('#custom-class')}>
							Take a Class
						</LeftButton>
						<LeftButton variant='contained' startIcon={<CollegeConsultingIcon className='roadmap_icon' />} onClick={handleLinkClick('#college-consulting')}>
							College Consulting
						</LeftButton>
						<LeftButton variant='contained' startIcon={<FreeResourceIcon className='roadmap_icon' />} onClick={handleLinkClick('#free-resource')}>
							Free Resources
						</LeftButton>
					</Stack>
				</div>
			</div>
		</RoadMapPaper>
	);
};

export default QuickLinks;
