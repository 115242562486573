import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import Button, { ButtonProps } from '@mui/material/Button';
import './ForgotPassword.css';
import Logo from '../../assets/img/common/logo.png';
import LeftImg from '../../assets/img/common/left.png';
import CustomTextField from '../../components/CustomTextField';
import { common } from '../../assets/jss/messages';
import { useDispatch } from 'react-redux';
import { updateCommon } from '../../reduxstate/slices/portalSlice';
import apiQuery from '../../apicall/apiQuery';

const ForgotPasswordPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	color: '#FFFF',
	height: '90vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	borderRadius: '2vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsRegular',
	fontSize: '1vmax',
	justifyContent: 'center',
	minWidth: '12vw',
	width: 'auto',
	'&:hover': {
		background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	},
}));

const ForgotPassword: React.FC = () => {
	useEffect(() => {});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [status, setStatus] = React.useState<null | boolean>(false);

	const isEmptyOrSpaces = (value: string | null | undefined) => {
		return value === undefined || value === null || value === '';
	};

	const regex = {
		email: new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'),
		number: new RegExp('^[0-9]+$'),
		password: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
	};

	const [userData, setUserData] = React.useState({
		data: {
			email: '',
		},
		error: {
			email: false,
		},
		errorMessage: {
			email: common.required,
		},
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		setUserData({
			...userData,
			data: {
				...userData.data,
				[name]: event.target.value,
			},
		});
	};

	const validateAll = () => {
		let isValidData = true;
		let email = false;
		let errorEmail = false;

		if (isEmptyOrSpaces(userData.data.email)) {
			email = true;
		} else if (!regex.email.test(userData.data.email)) {
			errorEmail = true;
			email = true;
		}

		if (email) {
			isValidData = false;
		}

		setUserData({
			...userData,
			error: {
				...userData.error,
				email: email,
			},
			errorMessage: {
				...userData.errorMessage,
				email: errorEmail ? common.invalid_email : common.required,
			},
		});

		return isValidData;
	};

	const handleResetPassword = () => {
		try {
			const validate = validateAll();
			if (validate) {
				apiQuery('auth/forgotPassword/' + userData.data.email, {}, 'GET', {})
					.then((res) => {
						setStatus(true);
						setUserData({
							...userData,
							data: {
								...userData.data,
								email: '',
							},
						});
					})
					.catch((err) => {
						FailLogin(err);
					});
			}
		} catch (error) {
			FailLogin(error);
		}
	};

	const FailLogin = (error: any) => {
		dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.forgot_password_error } }));
		console.error('Login failed:', error);
	};

	const handleLogoClick = () => {
		navigate('/');
	};

	const handleGotoLogin = () => {
		navigate('/login');
	};

	return (
		<ForgotPasswordPaper>
			<div className='forgotpwd_header'>
				<div className='forgotpwd_banner'></div>
				<img className='forgotpwd_logo' alt='' src={Logo} onClick={handleLogoClick} />
			</div>
			<div className='forgotpwd_content'>
				<div className='forgotpwd_left'>
					<img className='left_image' alt='' src={LeftImg} />
				</div>
				<div className='forgotpwd_right'>
					<Typography className='hello_text'>Hello!</Typography>
					{status ? (
						<Stack direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2}>
							<Typography className='forget_pwd_text_1'>{common.forgot_password_text_1}</Typography>
							<Typography className='forget_pwd_text_2'>{common.forgot_password_text_2}</Typography>
						</Stack>
					) : (
						<Stack direction={'column'} justifyContent={'center'} spacing={4}>
							<Typography className='sign_in_text'>Forgot Your Password?</Typography>
							<CustomTextField
								label='EMAIL ID'
								name='email'
								type='text'
								required
								className='input_field'
								error={userData.error.email}
								helperText={userData.errorMessage.email}
								value={userData.data.email}
								onChange={handleChange}
							/>
						</Stack>
					)}
					<Stack className='forgotpwd_botton_div' direction={'row'}>
						{status ? (
							<CustomButton variant='contained' onClick={handleGotoLogin}>
								BACK TO LOGIN
							</CustomButton>
						) : (
							<CustomButton variant='contained' onClick={handleResetPassword}>
								RESET PASSWORD
							</CustomButton>
						)}
					</Stack>
				</div>
			</div>
		</ForgotPasswordPaper>
	);
};

export default ForgotPassword;
