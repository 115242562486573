import React, { useState, useEffect } from 'react';
import { Grid, Typography, Avatar, Popover, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper, { PaperProps } from '@mui/material/Paper';
// import Slide, { SlideProps } from '@mui/material/Slide';
import './RoadMap.css';
import roadMapBg from '../../assets/img/roadmap/newpathbg.png';
import roadMapPath from '../../assets/img/roadmap/pathwaynew.png';
import collegeSign from '../../assets/img/roadmap/college.png';

const RoadMapPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	color: '#FFFF',
	height: '110vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const popupData: { [key: number]: string[] } = {
	1: [
		`<span class='road_blue_text_heading'>9th Grade</span>`,
		`<span class='road_blue_text'>Set goals</span> with parents and counselor`,
		`<span class='road_blue_text'>Work hard</span> to master foundational concepts and be rewarded with good grades`,
		`<span class='road_blue_text'>Get involved</span> with your favorite extracurriculars – sports, groups and clubs try something new`,
		`<span class='road_blue_text'>Volunteer</span> to understand what it feels like to give of yourself`,
	],
	2: [
		`<span class='road_blue_text_heading'>10th Grade</span>`,
		`<span class='road_blue_text'>Meet</span> with counselor to understand your current GPA and to ensure your course load is challenging`,
		`<span class='road_blue_text'>Learn</span> effective time management skills and work habits`,
		`<span class='road_blue_text'>Participate</span> in fewer extracurricular activities but take on more responsibilities`,
		`<span class='road_blue_text'>Sit</span> for a PSAT test to obtain a baseline score and gain experience`,
		`<span class='road_blue_text'>Develop</span> a productive summer plan be it work, academics, passionate interest`,
		`<span class='road_blue_text'>Evaluate</span> the past year and adjust plans for 11th grade.`,
	],
	3: [
		`<span class='road_blue_text_heading'>11th Grade</span>`,
		`<span class='road_blue_text'>Schedule</span> challenging courses – AP if possible to demonstrate your performance capabilities`,
		`<span class='road_blue_text'>Utilize</span> your Time Management skills to confidently be in control of your busy year`,
		`<span class='road_blue_text'>Investigate Leadership Positions</span> in your favorite activities/sports`,
		`<span class='road_blue_text'>Employ</span> necessary resources (e.g. teacher help) to learn and achieve good grades`,
		`<span class='road_blue_text'>Take Fall PSAT</span> for National Merit Scholarship Program`,
		`<span class='road_blue_text'>Volunteer</span> to understand what it feels like to give of yourself`,
		`<span class='road_blue_text'>Research</span> colleges on-line and with formal visits`,
		`<span class='road_blue_text'>Study Hard</span> for AP, SAT or ACT exams`,
		`<span class='road_blue_text'>Identify</span> teachers for letters of recommendation for AP, SAT or ACT exams`,
		`<span class='road_blue_text'>Utilize</span> the summer for your college application’s work`,
	],
	4: [
		`<span class='road_blue_text_heading'>12th Grade</span>`,
		`<span class='road_blue_text'>Finalize</span> your list of colleges`,
		`<span class='road_blue_text'>Ace your first semester courses</span> with parents and counselor`,
		`<span class='road_blue_text'>Find</span> your ‘college voice’`,
		`<span class='road_blue_text'>Take Pride</span> in your extracurricular accomplishments as a Senior`,
		`<span class='road_blue_text'>Formulate</span> your application strategy and submit them early`,
		`<span class='road_blue_text'>Select</span> your college of choice to attend.`,
		`<span class='road_blue_text'>Congratulate</span> yourself and thank everyone who believes in you`,
	],
};

const RoadMap: React.FC = () => {
	const [selectedData, setSelectedData] = useState<any>(popupData[1] || []);
	const [pointer, setPointer] = useState({
		elem: false,
		hover: false,
	});
	const indRef1 = React.useRef(null);
	const indRef2 = React.useRef(null);
	const indRef3 = React.useRef(null);
	const indRef4 = React.useRef(null);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(indRef1.current);
	const [anchorOrigin, setAnchorOrigin] = useState<any>({
		vertical: 'center',
		horizontal: 'right',
	});
	const [transformOrigin, setTransformOrigin] = useState<any>({
		vertical: 'center',
		horizontal: 'left',
	});

	const handlePopoverClose = () => {
		setPointer((prev) => ({ ...prev, elem: false }));
	};

	const handlePopoverOpen = (indicator: number) => () => {
		if (indicator === 3) {
			setAnchorOrigin({
				vertical: 'bottom',
				horizontal: 'center',
			});
			setTransformOrigin({
				vertical: 'top',
				horizontal: 'left',
			});
		} else {
			setAnchorOrigin({
				vertical: 'center',
				horizontal: 'right',
			});
			setTransformOrigin({
				vertical: 'center',
				horizontal: 'left',
			});
		}
		setSelectedData(popupData[indicator] || []);
		let target = null;
		switch (indicator) {
			case 1:
				target = indRef1.current;
				break;
			case 2:
				target = indRef2.current;
				break;
			case 3:
				target = indRef3.current;
				break;
			case 4:
				target = indRef4.current;
				break;
			default:
				target = null;
				break;
		}
		setAnchorEl(target);
		setPointer((prev) => ({ ...prev, elem: true, hover: true }));
	};

	useEffect(() => {}, []);

	const handleMouseEnter = () => {
		if (!pointer.hover) {
			setPointer((prev) => ({ ...prev, hover: true }));
		}
	};

	const handleMouseLeave = () => {
		if (pointer.hover) {
			setPointer((prev) => ({ ...prev, hover: false }));
		}
	};

	return (
		<RoadMapPaper>
			<img src={roadMapBg} alt='home-screen' className='road_bg_image' />
			<img src={roadMapPath} alt='home-screen' className='road_path_image' />
			<div className='header_Text_Container'>
				<div className='header_text'>YOUR ROAD TO COLLEGE!</div>
				<div className='header_sub_text'>SELECT YOUR GRADE TO LEARN MORE</div>
			</div>
			<div className='road_map_indicator_1'>
				<div className='road_map_indicator' ref={indRef1} onMouseEnter={handlePopoverOpen(1)} onMouseLeave={handlePopoverClose}>
					<Avatar className='road_map_avatar_1'>
						<Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
							<Stack direction={'row'} spacing={2}>
								9<span className='road_map_ordinal'>th</span>
							</Stack>
							<div className='road_map_grade'>Grade</div>
						</Stack>
					</Avatar>
				</div>
			</div>
			<div className='road_map_indicator_2'>
				<div className='road_map_indicator' ref={indRef2} onMouseEnter={handlePopoverOpen(2)} onMouseLeave={handlePopoverClose}>
					<Avatar className='road_map_avatar_2'>
						<Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
							<Stack direction={'row'} spacing={2}>
								10<span className='road_map_ordinal'>th</span>
							</Stack>
							<div className='road_map_grade'>Grade</div>
						</Stack>
					</Avatar>
				</div>
			</div>
			<div className='road_map_indicator_3'>
				<div className='road_map_indicator' ref={indRef3} onMouseEnter={handlePopoverOpen(3)} onMouseLeave={handlePopoverClose}>
					<Avatar className='road_map_avatar_3'>
						<Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
							<Stack direction={'row'} spacing={2}>
								11<span className='road_map_ordinal'>th</span>
							</Stack>
							<div className='road_map_grade'>Grade</div>
						</Stack>
					</Avatar>
				</div>
			</div>
			<div className='road_map_indicator_4'>
				<div className='road_map_indicator' ref={indRef4} onMouseEnter={handlePopoverOpen(4)} onMouseLeave={handlePopoverClose}>
					<Avatar className='road_map_avatar_4'>
						<Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
							<Stack direction={'row'} spacing={2}>
								12<span className='road_map_ordinal'>th</span>
							</Stack>
							<div className='road_map_grade'>Grade</div>
						</Stack>
					</Avatar>
				</div>
			</div>
			<img src={collegeSign} alt='college-board' className='college_sign_board' />
			<Popover
				open={pointer.elem || pointer.hover}
				anchorEl={anchorEl}
				anchorOrigin={anchorOrigin}
				transformOrigin={transformOrigin}
				className='road_popover'
				// TransitionComponent={SlideWithTransition}
				onClose={handleMouseLeave}
				sx={{
					pointerevents: 'auto',
				}}
			>
				<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
					<Grid container alignItems={'left'} direction={'column'} className='roadmap_popup_body' spacing={2}>
						{selectedData.map((item: any, index: number) => (
							<Grid item key={index}>
								<Typography className='road_popup_text' dangerouslySetInnerHTML={{ __html: item }}></Typography>
							</Grid>
						))}
					</Grid>
				</div>
			</Popover>
		</RoadMapPaper>
	);
};

export default RoadMap;
