import React, { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Stack, AccordionDetails, Grid } from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import Button, { ButtonProps } from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import './PrivateTutoring.css';
import Tutor from '../../assets/img/private_tutoring/tutor.png';
import PrivateTutor from '../../assets/img/private_tutoring/private_tutor.png';
// import { ReactComponent as DiagnosticIcon } from '../../assets/img/homepage/diagnostic.svg';

const Accordion = styled((props: AccordionProps) => <MuiAccordion {...props} />)(({ theme }) => ({
	borderRadius: '2vmax',
	boxShadow: 'none',
	width: '80%',
	backgroundColor: 'transparent',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={<></>} {...props} />)(({ theme }) => ({
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
		justifyContent: 'center',
	},
}));

// const HomeButton = styled(Button)<ButtonProps>(({ theme }) => ({
// 	color: '#FFFF',
// 	backgroundColor: '#0146C7',
// 	borderRadius: '1vmax',
// 	textTransform: 'none',
// 	fontFamily: 'PoppinsSemiBold',
// 	fontSize: '0.9vmax',
// 	'&:hover': {
// 		backgroundColor: '#0146C7',
// 	},
// }));

const RightButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	borderTopLeftRadius: '1vmax',
	borderBottomLeftRadius: '1vmax',
	textTransform: 'uppercase',
	fontFamily: 'PoppinsMedium',
	fontSize: '1vmax',
	justifyContent: 'center',
	padding: '0.5vmax',
	width: '80%',
	'&:hover': {
		background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	},
}));

const PrivateTutoring: FunctionComponent = () => {
	const [expanded, setExpanded] = React.useState<string | false>();
	const navigate = useNavigate();

	const handleChangeExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	const popupDetails: { [key: string]: any } = {
		panel1: {
			title: 'Standardized Test Prep',
			subTitle: 'No two students are alike, and neither are their study plans.',
			summary: 'Our team of skilled tutors take the time to understand your learning style, goals, and challenges, ensuring that every session is tailored to your unique needs.',
			button: [
				{ text: 'Our Success Stories', link: '#success-story' },
				{ text: 'Learn About Our Technology', link: '#technology' },
				{ text: 'Schedule a Consultation', link: '#consultation-plan' },
			],
		},
		panel2: {
			title: 'Academic Tutoring',
			subTitle: 'No two students are alike, and neither are their study plans.',
			summary: 'Our team of skilled tutors take the time to understand your learning style, goals, and challenges, ensuring that every session is tailored to your unique needs.',
			button: [
				{ text: 'Our Success Stories', link: '#success-story' },
				{ text: 'Learn About Our Technology', link: '#technology' },
				{ text: 'Schedule a Consultation', link: '#consultation-plan' },
			],
		},
		panel3: {
			title: 'Personalized programs',
			subTitle: 'No two students are alike, and neither are their study plans.',
			summary: 'Our team develops personalized programs for students and families facing unique challenges and circumstances.',
			button: [
				{ text: 'Our Success Stories', link: '#success-story' },
				{ text: 'Learn About Our Technology', link: '#technology' },
				{ text: 'Schedule a Consultation', link: '#consultation-plan' },
			],
		},
	};

	const handleLinkClick = (url: string) => (event: React.MouseEvent<HTMLElement>) => {
		navigate(`/${url}`);
		setTimeout(() => {
			const targetId = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
			const targetSection = document.getElementById(targetId || '');
			if (targetSection) {
				window.scrollTo({
					top: targetSection.offsetTop,
					behavior: 'smooth',
				});
			}
		}, 200);
	};

	return (
		<div className='private-tutoring'>
			<div className='pt-frame-parent4'>
				<div className='private-tutoring-parent'>
					<div className='private-tutoring-header'>private tutoring</div>
					<div className='pt-frame-child' />
				</div>
				<div className='menuitems-parent'>
					<Stack className='menuitems' direction={'column'} spacing={2}>
						<Accordion expanded={expanded === 'panel1'} onChange={handleChangeExpand('panel1')}>
							<AccordionSummary className='tutor_accordian_summary' aria-controls='panel1d-content' id='panel1d-header'>
								<Typography className='tutor_accordian_header'>Standardized Test Prep</Typography>
							</AccordionSummary>
							<AccordionDetails className='tutor_accordian_detail'>
								<Stack className='tutor_accordian_detail_stack' justifyContent={'center'} alignItems={'center'} spacing={0.5}>
									<Typography className='tutor_accordian_detail_text'> SAT | ACT</Typography>
									<Typography className='tutor_accordian_detail_text'>IELTS | TOEFL</Typography>
									<Typography className='tutor_accordian_detail_text'>GRE | LSAT | GMAT | SSAT</Typography>
								</Stack>
							</AccordionDetails>
						</Accordion>
						<Accordion expanded={expanded === 'panel2'} onChange={handleChangeExpand('panel2')}>
							<AccordionSummary className='tutor_accordian_summary' aria-controls='panel2d-content' id='panel2d-header'>
								<Typography className='tutor_accordian_header'>Academic Tutoring</Typography>
							</AccordionSummary>
							<AccordionDetails className='tutor_accordian_detail'>
								<Stack className='tutor_accordian_detail_stack' justifyContent={'center'} alignItems={'center'} spacing={0.5}>
									<Typography className='tutor_accordian_detail_text'>K-12 Subject Tutoring | AP | IB </Typography>
								</Stack>
							</AccordionDetails>
						</Accordion>
						<Accordion expanded={expanded === 'panel3'} onChange={handleChangeExpand('panel3')}>
							<AccordionSummary className='tutor_accordian_summary' aria-controls='panel3d-content' id='panel3d-header'>
								<Typography className='tutor_accordian_header'>Personalized programs</Typography>
							</AccordionSummary>
							<AccordionDetails className='tutor_accordian_detail'>
								<Stack className='tutor_accordian_detail_stack' justifyContent={'center'} alignItems={'center'} spacing={0.5}>
									<Typography className='tutor_accordian_detail_text'>Homeschooling</Typography>
									<Typography className='tutor_accordian_detail_text'>School Transfers | Gap Years</Typography>
								</Stack>
							</AccordionDetails>
						</Accordion>
					</Stack>
					{!expanded ? (
						<div className='pt-frame-parent5'>
							<div className='every-student-is-unique-our-e-wrapper'>
								<div className='every-student-is-container'>
									<p className='every-student-is-unique'>
										<b>Every student is unique.</b>
									</p>
									<p className='our-expert-tutors'>
										Our expert tutors are dedicated to crafting a personalized learning plan tailored to each student's strengths, weaknesses, and learning style. We're more than just tutors – we're
										mentors, motivators, and partners in your educational journey.
									</p>
								</div>
							</div>
							<img className='photoframe-icon' alt='' src={Tutor} />
						</div>
					) : (
						<div className='pt-frame-parent5 tutor_sub_page'>
							<div className='private-tutoring-parent'>
								<div className='tutor_sub_page_header'>{popupDetails[expanded]?.title}</div>
							</div>
							<div className='tutor_sub_page_summary_div'>
								<p className='tutor_sub_page_summary'>
									<b>{popupDetails[expanded]?.subTitle}</b>
								</p>
								<p className='tutor_sub_page_summary'>{popupDetails[expanded]?.summary}</p>
							</div>
							<Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
								<Grid item xs={6}>
									<img className='tutor_sub_page_photo' alt='' src={PrivateTutor} />
								</Grid>
								<Grid item xs={6}>
									<Stack justifyContent={'center'} alignItems={'flex-end'} spacing={2}>
										{(popupDetails[expanded]?.button || []).map((item: any, index: number) => (
											<RightButton variant='contained' onClick={handleLinkClick(item.link)}>
												{item.text}
											</RightButton>
										))}
									</Stack>
								</Grid>
							</Grid>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default PrivateTutoring;
