import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import Button, { ButtonProps } from '@mui/material/Button';
import './Payment.css';
import Logo from '../../assets/img/common/logo.png';
import LeftImg from '../../assets/img/common/left.png';
import { common } from '../../assets/jss/messages';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updatePaymentState } from '../../reduxstate/slices/portalSlice';
import { RootState } from '../../reduxstate';
import { useReactToPrint } from 'react-to-print';

const PaymentPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	color: '#FFFF',
	height: '90vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	borderRadius: '2vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsRegular',
	fontSize: '1vmax',
	justifyContent: 'center',
	minWidth: '12vw',
	width: 'auto',
	'&:hover': {
		background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	},
}));

const Payment: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { paymentState } = useSelector((state: RootState) => state.portal);
	const payment_source_from: string | null = sessionStorage.getItem('payment_source_from');
	const [redirectUrl, setRedirectUrl] = useState('');
	const componentRef = useRef(null);

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	useEffect(() => {
		const redirect_url: string | null = sessionStorage.getItem('redirect_url');
		if (redirect_url) {
			setRedirectUrl(redirect_url);
			sessionStorage.removeItem('redirect_url');
		}
		// const enroll_course_id: string | null = sessionStorage.getItem('enroll_course_id');
		// var pathArray = window.location.pathname.split('/');
		// let isSuccess = false;
		// if (pathArray.length >= 3) {
		// 	isSuccess = pathArray[2] === 'sccess';
		// }

		// if (isSuccess) {
		// 	setPaymentStatus('true');
		// 	if (!isEmptyOrSpaces(enroll_course_id)) {
		// 		sessionStorage.removeItem('enroll_course_id');
		// 		saveEnrollment(enroll_course_id).then((result) => {
		// 			if (result) dispatch(updateUser({ registerData: result }));
		// 		});
		// 	}
		// } else {
		// 	setPaymentStatus('false');
		// }
		// eslint-disable-next-line
	}, []);

	const handleLogoClick = () => {
		navigate('/');
	};

	const handleGoBackBtn = () => {
		sessionStorage.removeItem('payment_source_from');
		dispatch(updatePaymentState({ isError: false, responseMessage: '' }));
		if (redirectUrl) {
			navigate(redirectUrl);
		} else {
			navigate('/');
		}
	};

	return (
		<PaymentPaper>
			<div className='payment_header'>
				<div className='payment_banner'></div>
				<img className='payment_logo' alt='' src={Logo} onClick={handleLogoClick} />
			</div>
			<Typography className='payment_heading'> Payment Confirmation</Typography>
			<div className='payment_content'>
				<div className='payment_left' ref={componentRef}>
					{paymentState.isError ? (
						<img className='left_image' alt='' src={LeftImg} />
					) : (
						<Stack direction={'column'} spacing={1} justifyContent={'center'} className='payment_bill'>
							<Typography className='payment_bill_header'>Billing Details</Typography>
							<Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
								<Typography className='payment_bill_txt'>Name On Account</Typography>
								<Typography className='payment_bill_txt'>{paymentState?.data?.nameOnAccount}</Typography>
							</Stack>
							<Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
								<Typography className='payment_bill_txt'>Address</Typography>
								<Typography className='payment_bill_txt'>{paymentState?.data?.address}</Typography>
							</Stack>
							<Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
								<Typography className='payment_bill_txt'>City</Typography>
								<Typography className='payment_bill_txt'>{paymentState?.data?.city}</Typography>
							</Stack>
							<Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
								<Typography className='payment_bill_txt'>State</Typography>
								<Typography className='payment_bill_txt'>{paymentState?.data?.state}</Typography>
							</Stack>
							<Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
								<Typography className='payment_bill_txt'>Postal Code</Typography>
								<Typography className='payment_bill_txt'>{paymentState?.data?.postCode}</Typography>
							</Stack>
							<Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
								<Typography className='payment_bill_txt'>Country</Typography>
								<Typography className='payment_bill_txt'>{paymentState?.data?.country}</Typography>
							</Stack>
							<Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
								<Typography className='payment_bill_txt'>Amount</Typography>
								<Typography className='payment_bill_txt'>$ {paymentState?.data?.amount}</Typography>
							</Stack>
							<Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
								<Typography className='payment_bill_txt'>Date of Transaction</Typography>
								<Typography className='payment_bill_txt'>{`${new Date().toDateString()} ${new Date().toLocaleTimeString()}`}</Typography>
							</Stack>
							<br />
							<Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
								<Typography className='payment_bill_txt'>
									<mark>Please print this page for your records</mark>
								</Typography>
								<CustomButton variant='contained' onClick={handlePrint}>
									PRINT
								</CustomButton>
							</Stack>
						</Stack>
					)}
				</div>
				<div className='payment_right'>
					<Typography className='hello_text'>{!paymentState.isError ? common.payment_succsss_1 : paymentState.isError ? common.payment_fail_1 : ''}</Typography>
					<Typography className='sign_in_text'>
						{!paymentState.isError ? (payment_source_from === 'enroll' ? common.payment_succsss_2 : common.payment_succsss_4) : paymentState.isError ? common.payment_fail_2 : ''}
					</Typography>
					<br />
					<Typography className='failed_payment_text'>{paymentState.isError && paymentState.responseMessage ? `Error: ${paymentState.responseMessage}` : ''}</Typography>
					<br />
					<Stack direction={'column'} justifyContent={'center'} spacing={4} alignItems={'center'}>
						<Typography className='payment_text'>{!paymentState.isError ? common.payment_succsss_3 : paymentState.isError ? common.payment_fail_3 : ''}</Typography>

						<CustomButton variant='contained' onClick={handleGoBackBtn}>
							{payment_source_from === 'enroll' ? 'BACK TO ENROLL' : 'BACK TO HOME'}
						</CustomButton>
					</Stack>
				</div>
			</div>
		</PaymentPaper>
	);
};

export default Payment;
