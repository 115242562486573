import React, { ReactNode } from 'react';
import { FormControl, InputLabel , FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './CustomDropdownField.css';

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
}));

interface CustomDropdownFieldProps  {
  label: string;
  name: string;
  value: any;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  inputCenter?: boolean;
  noLabelShrink?: boolean;
  isTextHeader?: boolean;
  children?: ReactNode;
  onChange: (event: SelectChangeEvent<HTMLInputElement>, ...rest: any[]) => void;
  options?: { id: number; name: string }[];
}

const CustomDropdownField: React.FC<CustomDropdownFieldProps> = (props) => {
  const {
    name,
    label,
    value,
    error,
    helperText,
    required,
    disabled,
    className,
    noLabelShrink,
    onChange,
    children,
    options,
    ...rest
  } = props;

  const fieldClasses = className !== undefined ? `dd_field ${className}` : 'dd_field';
  
	const handleSchoolChange = (event: SelectChangeEvent<HTMLInputElement>, ...rest: any[]) => {
		onChange(event, ...rest);
	};

  return (
    <FormControl className={fieldClasses} {...rest} error={error}>
      {label !== '' && (
       <StyledInputLabel
       classes={{
         root: 'dd_input_label',
         focused: 'dd_input_label_focused' ,
         shrink: 'dd_input_label_shrink',
       }}
       required={required}
     >
       {label}
     </StyledInputLabel>
      )}
      <Select
        label={label}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: '2px solid #0146C6 !important',
            fontSize: '1.2vmax',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0146C6 ',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0146C6',
          },
        }}
        name={name}
        value={value}
        error={error}
        type='select'
        className={fieldClasses}
        onChange={handleSchoolChange}
        fullWidth
        disabled={disabled}
        // inputProps={{
        //   classes: {
        //     root: 'dd_input_text', 
        //     input: 'dd_input_header',
        //     underline: 'dd_input_header',
        //     notchedOutline: 'dd_input_header',
        //   },
        //   style: {
        //     fontFamily: 'PoppinsSemiBold',
        //     fontSize: '7vmax',
        //     color: 'var(--primary-font-color)',
        //     borderRadius: '2vmax',
        //   },
        // }}
        MenuProps={{
					classes: { paper: 'dd_menuPaper' },
					anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
					transformOrigin: { vertical: 'top', horizontal: 'left' },
				}}
      >
            {children}
      </Select>
      <FormHelperText>{error ? helperText : ''}</FormHelperText>
    </FormControl>
  );
};

export default CustomDropdownField;
