import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';
import { Language } from '@mui/icons-material';
import Paper, { PaperProps } from '@mui/material/Paper';
import './FreeResource.css';
import resourceBg from '../../assets/img/free_resource/free_resource.png';
import { getStrapiData } from '../../shared';
import { marked } from 'marked';

const ResourcePaper = styled(Paper)<PaperProps>(({ theme }) => ({
	margin: '2vh 0',
	color: '#FFFF',
	height: '100vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const FreeResource: React.FC = () => {
	const [resouceList, setResouceList] = useState<null | any>([]);
	useEffect(() => {
		GetResoureData();
		// eslint-disable-next-line
	}, []);

	const GetResoureData = async () => {
		try {
			const data = await getStrapiData('free-resources?sort=displayOrder:asc');
			if (data.length > 0) {
				setResouceList(data || []);
			}
		} catch (error) {}
	};

	return (
		<ResourcePaper>
			<img src={resourceBg} alt='home-screen' className='resource_bg_image' />
			<Stack direction={'column'} className='resource_item_top'>
				<Typography className='resource_header'>FREE RESOURCE</Typography>
			</Stack>
			<Stack direction={'column'} className='resource_item_bottom'>
				{resouceList.map((item: any, index: number) => (
					<div key={index} className='resource_link' rel='noreferrer'>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<Language className='all_icon resource_icon' />
							&nbsp;
							<div>
								<div dangerouslySetInnerHTML={{ __html: item?.attributes?.details ? marked(item.attributes.details) : '' }} />
							</div>
						</div>
					</div>
				))}
			</Stack>
		</ResourcePaper>
	);
};

export default FreeResource;
