import axios from 'axios';
import { Cookies } from 'react-cookie';
import { getDomain, getHostName } from '../shared';
let refreshCount = 0;

export const getHost = (url: string) => {
	if (localStorage.getItem('devserver') === '1') {
		return localStorage.getItem('devurl');
	}
	const loc = window.location;
	const prot = loc.protocol;
	const mode: string = loc.hostname === 'localhost' ? 'development' : 'production';
	let prodURL = `api.${getHostName()}`;
	let apiUrl = mode === 'development' ? 'localhost:3001/api' : prodURL;
	const auth = ['auth', 'users', 'register'];
	const register = ['registration', 'payment'];
	const transactions = ['creditBankAccount'];
	if (auth.some((v: any) => url.includes(v))) {
		apiUrl = mode === 'development' ? 'localhost:3001/api' : `${prodURL}/authentication/api`;
	} else if (register.some((v: any) => url.includes(v))) {
		apiUrl = mode === 'development' ? 'localhost:3002/api' : `${prodURL}/registration/api`;
	} else if (transactions.some((v: any) => url.includes(v))) {
		apiUrl = mode === 'development' ? 'localhost:3010/transaction' : `${prodURL}/transaction`;
	}

	return prot + '//' + apiUrl;
};

export const getApiUrl = (url: string) => {
	return `${getHost(url)}/${url}`;
};

const Apicall = (url: string, method: string, body: any) => {
	refreshCount = 0;
	const cookies = new Cookies();
	const access_token: string | null = cookies.get('access_token');
	const options: any = {
		method: method || 'GET',
		url: `${getHost(url)}/${url}`,
		//withCredentials: true,
		data: body,
		headers: {
			Authorization: 'Bearer ' + access_token,
		},
	};
	//axios.defaults.withCredentials = true;
	const res = axios(options);
	const result = parseStatus(res, options);
	return result;
};
export default Apicall;

const retryApiCall = async (options: any) => {
	const cookies = new Cookies();
	const refresh_token: string | null = cookies.get('refresh_token');
	const method: string = 'POST';
	const url: string = 'auth/refreshtoken';
	const body: any = {};
	const options_new: any = {
		method: method,
		url: `${getHost(url)}/${url}`,
		data: body,
		headers: {
			refreshtoken: refresh_token,
		},
	};

	const res_new = axios(options_new);
	return await new Promise((resolve, reject) => {
		res_new
			.then((response: any) => {
				const status = response.status;
				if (status) {
					if (status >= 200 && status < 300) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				}
			})
			.catch((reason) => {
				console.log(reason);
				return reject({ status: reason.status, response: reason.message, reason: reason.response ? reason.response.data : [] });
			});
	});
};

const parseStatus = async (res: any, options: any) => {
	return await new Promise((resolve, reject) => {
		res
			.then((response: any) => {
				const status = response.status;
				if (status) {
					if (status >= 200 && status < 300) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				} else {
					reject({ status: 500, response: response.message });
				}
			})
			.catch((reason: any) => {
				console.log(reason);
				if (refreshCount <= 5 && reason.response && reason.response.data.statusCode === 403) {
					refreshCount++;
					retryApiCall(options)
						.then((result: any) => {
							const cookies = new Cookies();
							const domain = getDomain();
							cookies.remove('access_token');
							cookies.remove('refresh_token');
							cookies.set('access_token', result.access_token, domain);
							cookies.set('refresh_token', result.refresh_token, domain);

							options.headers = {
								Authorization: 'Bearer ' + result.access_token,
							};
							const res_new = axios(options);
							const result_new = parseStatus(res_new, options);
							resolve(result_new);
						})
						.catch((err) => {
							console.log(err);
							redirectUrl();
							reject(err);
						});
				} else if (refreshCount > 5 && reason.response && reason.response.data.statusCode === 403) {
					redirectUrl();
					return reject({ status: reason.status, response: reason.message, reason: reason.response ? reason.response.data : [] });
				} else {
					return reject({ status: reason.status, response: reason.message, reason: reason.response ? reason.response.data : [] });
				}
			});
	});
};

const redirectUrl = () => {
	// let redirect_url = `${window.location.protocol}//${window.location.host}/login`;
	// window.location.replace(redirect_url);
};
