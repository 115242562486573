import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import Button, { ButtonProps } from '@mui/material/Button';
import './UserVerification.css';
import Logo from '../../assets/img/common/logo.png';
import LeftImg from '../../assets/img/common/left.png';
import { common } from '../../assets/jss/messages';
import { useDispatch } from 'react-redux';
import { updateCommon } from '../../reduxstate/slices/portalSlice';
import apiQuery from '../../apicall/apiQuery';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';

const UserVerificationPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	color: '#FFFF',
	height: '90vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	borderRadius: '2vmax',
	textTransform: 'none',
	fontFamily: 'PoppinsRegular',
	fontSize: '1vmax',
	justifyContent: 'center',
	width: '10vw',
	'&:hover': {
		background: 'linear-gradient(180deg, #0146C7 0%, #022870 100%)',
	},
}));

const UserVerification: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [validUser, setVaildUser] = useState('');
	const [firstLoad, setFirstLoad] = React.useState(true);
	useEffect(() => {
		if (firstLoad) {
			setFirstLoad(false);
			let search = window.location.search;
			let params = new URLSearchParams(search);
			let guid = params.get('token');
			if (guid) {
				const body = {
					token: guid,
				};
				apiQuery('auth/verifyregister', {}, 'POST', body)
					.then((result: any) => {
						setVaildUser('true');
						dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'success', message: common.verify_success } }));
					})
					.catch((error) => {
						if (error?.reason?.statusCode === 409) {
							setVaildUser('valid');
							dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'info', message: common.verify_vaild_user } }));
						} else {
							setVaildUser('false');
							dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.verify_error } }));
						}
					});
			}
		}
		// eslint-disable-next-line
	}, []);

	const handleLogoClick = () => {
		navigate('/');
	};

	const handleLoginClick = () => {
		navigate('/login');
	};

	return (
		<UserVerificationPaper>
			<div className='userverify_header'>
				<div className='userverify_banner'></div>
				<img className='userverify_logo' alt='' src={Logo} onClick={handleLogoClick} />
			</div>
			<div className='userverify_content'>
				<div className='userverify_left'>
					<img className='left_image' alt='' src={LeftImg} />
				</div>
				<div className='userverify_right'>
					<Typography className='hello_text'>Hello!</Typography>
					<Typography className='sign_in_text'>Identity Verification in Process</Typography>
					<br />
					<br />
					<Stack direction={'column'} spacing={4} justifyContent={'center'} alignItems={'center'}>
						{(validUser === 'true' || validUser === 'valid') && <VerifiedIcon className='userverify_success_icon' />}
						{validUser === 'false' && <CancelIcon className='userverify_fail_icon' />}
						<Typography className='userverify_text'>
							{validUser === 'true' ? common.verify_success : validUser === 'false' ? common.verify_error : validUser === 'valid' ? common.verify_vaild_user : ''}
						</Typography>
						<CustomButton variant='contained' onClick={handleLoginClick}>
							Go To Login
						</CustomButton>
					</Stack>
				</div>
			</div>
		</UserVerificationPaper>
	);
};

export default UserVerification;
