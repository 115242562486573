import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import './Banner.css';
import { getStrapiData } from '../../shared';

const BannerBar: React.FC = () => {
	const [isBanner, setIsBanner] = useState<null | boolean>(false);
	const [bannerText, setBannerText] = useState<null | any>('');
	useEffect(() => {
		GetBannerData();
		// eslint-disable-next-line
	}, []);

	const GetBannerData = async () => {
		try {
			const filter = `filters[$and][0][startsOn][$lte]=${new Date().toISOString()}&filters[$and][1][expiresOn][$gte]=${new Date().toISOString()}&sort=startsOn:asc`;
			const data = await getStrapiData(`alerts?${filter}`);
			if (data.length > 0) {
				setIsBanner(true);
				const bannertextList: any = [];
				data.map((item: any) => bannertextList.push(item.attributes.description));
				const bannerText = bannertextList.join('<br/> <br/>');
				setBannerText(bannerText);
			}
		} catch (error) {}
	};

	return (
		<Grid container direction={'row'} alignItems={'center'} className='banner_div'>
			{isBanner && (
				<Grid item xs>
					<Typography className='banner_typography' dangerouslySetInnerHTML={{ __html: bannerText }}></Typography>
				</Grid>
			)}
		</Grid>
	);
};

export default BannerBar;
