import { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import './CollegeConsulting.css';
import People3 from '../../assets/img/college/people3.png';
import People2 from '../../assets/img/college/people2.png';
import { ReactComponent as Arrow } from '../../assets/img/college/arrow.svg';
import { useNavigate } from 'react-router-dom';

const HomeButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#FFFF',
	backgroundColor: '#0146C7',
	borderRadius: '2vmax',
	textTransform: 'none',
	padding: '0.5vmax 2vmax',
	fontFamily: 'PoppinsRegular',
	fontSize: '1.5vmax',
	// width: '25vw',
	'&:hover': {
		backgroundColor: '#0146C7',
	},
}));

const CollegeConsulting: FunctionComponent = () => {
	const navigate = useNavigate();
	const handleLinkClick = (url: string) => (event: React.MouseEvent<HTMLElement>) => {
		navigate(`/${url}`);
		setTimeout(() => {
			const targetId = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
			const targetSection = document.getElementById(targetId || '');
			if (targetSection) {
				window.scrollTo({
					top: targetSection.offsetTop,
					behavior: 'smooth',
				});
			}
		}, 200);
	};

	return (
		<div className='college-consulting'>
			<div className='cc-frame-parent1'>
				<div className='cc-frame-parent2'>
					<div className='cc-frame-parent3'>
						<div className='cc-frame-parent4'>
							<div className='college-consulting-parent'>
								<div className=''>college consulting</div>
								<div className='cc-frame-child' />
							</div>
							<div className='our-experts-take-container'>
								<p className='our-experts-take'>NAVIGATING THE COLLEGE ADMISSION PROCESS FOR YOUR DREAM COLLEGE OR UNIVERSITY CAN BE CHALLENGING.</p>
								<p className='our-experts-take'>ALPHAPREP CONSULTANTS PROVIDE PRESONALIZED ATTENTION AND GUIDE YOU EVERY STEP OF THE WAY.</p>
								<p className='our-experts-take'>&nbsp;</p>
								{/* <p className='our-experts-take'>Applying and gaining admission to the college or university of your choice is difficult to manage.</p> */}
							</div>
						</div>
						<b className='lets-work-together'>LET’S WORK TOGETHER!</b>
					</div>
					<HomeButton variant='contained' endIcon={<Arrow />} onClick={handleLinkClick('#consultation-plan')}>
						Find A Plan For You
					</HomeButton>
				</div>
				<div className='cc-frame-parent5'>
					<img className='cc-frame-inner-women' alt='' src={People3} />
					<img className='cc-frame-inner' alt='' src={People2} />
				</div>
			</div>
		</div>
	);
};

export default CollegeConsulting;
