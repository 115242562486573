import { useEffect, useState, useRef, RefObject } from 'react';

const useOnScreen = (ref: RefObject<HTMLElement>) => {
	const observerRef = useRef<IntersectionObserver | null>(null);
	const [isOnScreen, setIsOnScreen] = useState(false);

	useEffect(() => {
		observerRef.current?.disconnect();
		observerRef.current = new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting));
	}, []);

	useEffect(() => {
		if (ref.current && observerRef.current) {
			observerRef.current.observe(ref.current);
		}
		return () => {
			observerRef.current?.disconnect();
		};
	}, [ref]);

	return isOnScreen;
};

export { useOnScreen };
