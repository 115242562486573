import { FunctionComponent, useState, useEffect } from 'react';
import { Stack, Grid, Card } from '@mui/material';
import ArrowImg from '../../assets/img/common/arrow.png';
import './ConsultationPlan.css';
import { ReactComponent as Message } from '../../assets/img/college/message.svg';
import { IconButton } from '@mui/material';
import { ReactComponent as Phone } from '../../assets/img/college/phone.svg';
import { Email } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '../../reduxstate/index';
import { getStrapiData } from '../../shared';
import { marked } from 'marked';

const ConsultationPlan: FunctionComponent = () => {
	const [planList, setPlanList] = useState<null | any>([]);
	const { mobile, emailId, whatsapp } = useSelector((state: RootState) => state.portal);
	const WhatsappWeb = whatsapp.replace("+1", "");
	useEffect(() => {
		GetPlanData();
		// eslint-disable-next-line
	}, []);

	const GetPlanData = async () => {
		try {
			const data = await getStrapiData('college-consultings?sort=displayOrder:asc');
			if (data.length > 0) {
				setPlanList(data || []);
			}
		} catch (error) {}
	};

	const productContainers = document.querySelectorAll('.product-container');
	const nxtBtn = document.querySelectorAll('.nxt-btn');
	const preBtn = document.querySelectorAll('.pre-btn');

	productContainers.forEach((item, i) => {
		let containerDimensions = item.getBoundingClientRect();
		let containerWidth = containerDimensions.width;

		nxtBtn[i].addEventListener('click', () => {
			item.scrollLeft += containerWidth;
		});

		preBtn[i].addEventListener('click', () => {
			item.scrollLeft -= containerWidth;
		});
	});

	return (
		<Stack className='consultation-plan' direction={'column'} justifyContent='space-between' spacing={2}>
			<div></div>
			<div className='plan_body'>
				{(planList || []).length > 0 && (
					<section className='product'>
						<button className='pre-btn'>
							<img src={ArrowImg} alt='' />
						</button>
						<button className='nxt-btn'>
							<img src={ArrowImg} alt='' />
						</button>
						<div className='product-container'>
							{(planList || []).map((item: any, index: number) => (
								<div key={index} className='consultation-product-card'>
									<Grid item xs={4}>
										<Card variant='elevation' className='cp_cards'>
											<div className='cp_cards_header'>{item.attributes.title}</div>
											<div className='cp_cards_body' dangerouslySetInnerHTML={{ __html: marked.parse(item.attributes.description) }}></div>
										</Card>
									</Grid>
								</div>
							))}
						</div>
					</section>
				)}
			</div>

			<Stack direction={'column'} spacing={2}>
				<div className='schedule-your-consultation'>Schedule your consultation today</div>
				<div className='consultation-icon-div'>
					<a href={`https://api.whatsapp.com/send?phone=${WhatsappWeb}`} target='_blank' rel='noreferrer'>
						<Message className='consultation-icon' />
					</a>
					<a href={`mailto:${emailId}`}>
						<IconButton className='mail_container'>
							<Email className='mail_icon' />
						</IconButton>
					</a>
					<a href={`tel:${mobile}`}>
						<Phone className='consultation-icon' />
					</a>
				</div>
			</Stack>
		</Stack>
	);
};

export default ConsultationPlan;
