import { FunctionComponent, useState, useEffect } from 'react';
import './SuccessStories.css';
import ArrowImg from '../../assets/img/common/arrow.png';
import Mobile from '../../assets/img/success_stories/mobile.png';
import { getStrapiData } from '../../shared';
import { marked } from 'marked';

const SuccessStories: FunctionComponent = () => {
	const [storyList, setStoryList] = useState<null | any>([]);
	useEffect(() => {
		GetStoriesData();
		// eslint-disable-next-line
	}, []);

	const GetStoriesData = async () => {
		try {
			const data = await getStrapiData('success-stories?sort=displayOrder:asc');
			if (data.length > 0) {
				setStoryList(data || []);
			}
		} catch (error) {}
	};

	const productContainers = document.querySelectorAll('.product-container');
	const nxtBtn = document.querySelectorAll('.nxt-btn');
	const preBtn = document.querySelectorAll('.pre-btn');

	productContainers.forEach((item, i) => {
		let containerDimensions = item.getBoundingClientRect();
		let containerWidth = containerDimensions.width;

		nxtBtn[i].addEventListener('click', () => {
			item.scrollLeft += containerWidth;
		});

		preBtn[i].addEventListener('click', () => {
			item.scrollLeft -= containerWidth;
		});
	});

	return (
		<div className='outcomes'>
			<div className='success-story-main'>
				<div className='success-stories-parent'>
					<div className='success-stories'>success stories</div>
					<div className='ss-frame-child' />
				</div>
				<div className='story_body'>
					{(storyList || []).length > 0 && (
						<section className='product'>
							<button className='pre-btn'>
								<img src={ArrowImg} alt='' />
							</button>
							<button className='nxt-btn'>
								<img src={ArrowImg} alt='' />
							</button>
							<div className='product-container'>
								{(storyList || []).map((item: any, index: number) => (
									<div key={index} className='product-card story_card_override mobile-1-parent'>
										<img className='mobile-1-icon' alt='' src={Mobile} />
										<div className='ss-frame-group'>
											<div className='success-user-parent'>
												<div className='success-user'>{item.attributes.name}</div>
												<div className='success-user-occupation'>{item.attributes.role}</div>
											</div>
											<div className='review1'>
												<div className='review-text' dangerouslySetInnerHTML={{ __html: marked.parse(item.attributes.review) }}></div>
											</div>
										</div>
									</div>
								))}
							</div>
						</section>
					)}
				</div>
			</div>
		</div>
	);
};

export default SuccessStories;
