import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Paper, { PaperProps } from '@mui/material/Paper';
import './CancellationPolicy.css';
import Logo from '../../assets/img/common/logo.png';

const PaymentPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	color: '#FFFF',
	height: '100vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const CancellationPolicy: React.FC = () => {
	const navigate = useNavigate();
	useEffect(() => {
		// eslint-disable-next-line
	}, []);

	const handleLogoClick = () => {
		navigate('/');
	};

	return (
		<PaymentPaper>
			<div className='c_payment_header'>
				<div className='c_payment_banner'></div>
				<img className='c_payment_logo' alt='' src={Logo} onClick={handleLogoClick} />
			</div>
			<div className='cancellation_content'>
				<p className='can_policy_text'>
					Tutoring sessions must be cancelled at least 24 hours before the session takes place in order to receive a full refund or a credit. Other than that, there will be no refunds. Should you have
					any concern with information provided or the quality of the services provided, AlphaPrep will be more than happy to offer you an additional session or tutoring services to make sure that you
					are satsified. As soon as possible, please make us aware of the date, time, and instructor conducting the session or tutoring class that you attended and were not pleased with.
				</p>
				<h1 className='can_policy_header'>Contact Info</h1>
				<p className='can_policy_text1'>
					All inquiries concerning this statement and the Site in general may be sent to Customer Care, AlphaPrep, LLC.,66 Rivergate Lane, East Berlin, CT 06023 or{' '}
					<a href='mailto:info@alphaprep.com'>info@alphaprep.com</a>.
				</p>
				<p className='can_policy_text1'>
					Thank you for visiting <a href='http://www.alphaprep.com/'>www.alphaprep.com</a>
				</p>
			</div>
		</PaymentPaper>
	);
};

export default CancellationPolicy;
