import React, { ChangeEvent, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { FormControl, TextFieldVariants } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import './CustomTextField.css';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
	'& .MuiFormLabel-asterisk': {
		color: 'red',
	},
	'& .MuiInputBase-inputMultiline': {
		padding: '0 0.5vmax',
	},
}));

interface CustomTextFieldProps {
	label: string;
	name?: string;
	type?: string;
	value?: any;
	error?: boolean;
	helperText?: string;
	required?: boolean;
	disabled?: boolean;
	variant?: TextFieldVariants | undefined;
	className?: string;
	inputCenter?: boolean;
	noLabelShrink?: boolean;
	isTextHeader?: boolean;
	multiline?: boolean;
	rows?: number;
	rowsMax?: number;
	style?: any;
	InputProps?: any;
	children?: ReactNode;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>, ...rest: any[]) => void;
	maxLength?: number;
}
const CustomTextField: React.FC<CustomTextFieldProps> = (props) => {
	const {
		name,
		label,
		type,
		required,
		multiline,
		disabled,
		rowsMax,
		onChange,
		onKeyDown,
		value,
		error,
		helperText,
		rows,
		variant,
		className,
		children,
		inputCenter,
		noLabelShrink,
		isTextHeader,
		maxLength,
		InputProps,
		...rest
	} = props;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
		if (maxLength !== undefined) {
			const inputValue = event.target.value;
			if (inputValue.length > maxLength) {
				event.target.value = inputValue.slice(0, maxLength);
			}
		}
		if (onChange) onChange(event, ...rest);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, ...rest: any[]) => {
		if (onKeyDown) onKeyDown(event, ...rest);
	};

	const fieldClasses = className !== undefined ? `tf_input_text ${className}` : 'tf_input_text';
	return (
		<FormControl className='tf_form_root' {...rest}>
			<StyledTextField
				label={label}
				name={name}
				value={value}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				type={type}
				error={error}
				fullWidth
				helperText={error ? helperText : ''}
				disabled={disabled}
				className={fieldClasses}
				required={required}
				multiline={multiline}
				rows={rows}
				variant={variant}
				autoComplete='new-password'
				InputLabelProps={
					noLabelShrink
						? {
								shrink: false,
								classes: {
									root: 'tf_input_label_shrink',
								},
						  }
						: {
								classes: {
									root: 'tf_input_label',
									focused: 'focused',
									shrink: 'shrink',
								},
						  }
				}
				InputProps={
					isTextHeader
						? {
								...InputProps,
								classes: {
									root: 'tf_input_header',
									input: inputCenter ? 'tf_input_header_center' : 'tf_input_header',
									underline: 'tf_input_underline_header',
									notchedOutline: 'tf_input_notch_line',
								},
						  }
						: {
								...InputProps,
								classes: {
									root: 'tf_input_text',
									input: inputCenter ? 'tf_input_text_center' : 'tf_input_text',
									underline: 'tf_input_underline',
									notchedOutline: 'tf_input_notch_line',
								},
						  }
				}
			>
				{children}
			</StyledTextField>
		</FormControl>
	);
};

export default CustomTextField;
