import React from 'react';
import './Processing.css';
import { Stack, CircularProgress, Typography } from '@mui/material';

const Processing: React.FC<{ isProceesing: boolean }> = ({ isProceesing }) => {
	return isProceesing ? (
		<Stack className='wait_stack' justifyContent={'center'} alignItems={'center'} spacing={2}>
			<CircularProgress className='wait_icon' />
			<Typography className='wait_text'> Please wait ...</Typography>
		</Stack>
	) : (
		<></>
	);
};

export default Processing;
