import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

export const getInitData = createAsyncThunk('portal/getInitData', async () => {
  const data = [
    {id: 1, name: 'portal 1'},
    {id: 2, name: 'portal 2'},
    {id: 3, name: 'portal 3'}
  ];
  return data;
});

interface SysPref {
  GOOGLE_APP_ID: string;
  FB_APP_ID: string;
  MICROSOFT_APP_ID: string;
}

interface PaymentState {
  isError: boolean,
  responseMessage: string,
  data: any,
}

interface InitialStateTypes {
  data: Array<Object>;
  status: `idle` | `busy` | `failed` | `success`;
  error: null | string;
  mobile: string;
  emailId: string;
  whatsapp: string;
  snackbarDetail: any;
  dialogDetail: any;
  courseId: string | null;
  redirectURL: string;
  workflow: string;
  sysPref: SysPref;
  paymentState: PaymentState;
}

const initialState: InitialStateTypes = {
  data: [],
  status: 'idle',
  error: null,
  mobile: '+18888425742',
  emailId: 'info@alphaprep.com',
  whatsapp: '+18888425742',
  snackbarDetail: null,
  dialogDetail: {
    dialogOpen: false,
  },
  courseId: '',
  redirectURL: '',
  workflow: '',
  sysPref: {
    GOOGLE_APP_ID: `356061490085-2v0k30uligj3i8qr0tcuvtnigaun6cgj.apps.googleusercontent.com`,
    FB_APP_ID: `817265823388014`,
    MICROSOFT_APP_ID: `aac520a2-8e0c-44aa-b5f9-638a47946f2b`
  },
  paymentState:{
    isError: true,
    responseMessage: '',
    data: {}
  }
}

export const portalSlice = createSlice({
  name: 'portal',
  initialState,
  reducers: {
    insertData: (state, action: PayloadAction<Object>) => {
      state.data.push(action.payload);
    },
    updateCommon: (state, action: PayloadAction<Partial<InitialStateTypes>>) => {
      Object.assign(state, action.payload);
    },
    updateSysPref: (state, action: PayloadAction<Partial<SysPref>>) => {
      Object.assign(state.sysPref, action.payload);
    },
    updatePaymentState:(state, action: PayloadAction<Partial<PaymentState>>)=>{
      Object.assign(state.paymentState, action.payload);
    }
  },
});

export const { insertData, updateCommon, updateSysPref,updatePaymentState } = portalSlice.actions;

export default portalSlice.reducer;
