import React, { useEffect, FunctionComponent } from 'react';
import './Diagnostic.css';
import Tree from '../../assets/img/diagnostic/tree.png';
// import { ReactComponent as DiagnosticLight } from '../../assets/img/diagnostic/diagnostic_light.svg';
// import { ReactComponent as TestLight } from '../../assets/img/diagnostic/test_light.svg';
// import { ReactComponent as DiagnosticBold } from '../../assets/img/diagnostic/diagnostic_bold.svg';
// import { ReactComponent as TestBold } from '../../assets/img/diagnostic/test_bold.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateCommon } from '../../reduxstate/slices/portalSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../reduxstate/index';
import { Grid } from '@mui/material';
import { getStrapiData, getCmsURL, getHostName } from '../../shared';
import DiagnosticIcon from '../../assets/img/diagnostic/diagnostic_bold.svg';
import TestIcon from '../../assets/img/diagnostic/test_bold.svg';

const Diagnostic: FunctionComponent = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector((state: RootState) => state.user.userData);
	const register = useSelector((state: RootState) => state.user.registerData);
	const [testTypeList, setTestTypeList] = React.useState<null | any>([]);
	const [contentApi, setContentApi] = React.useState<string | null>(null);

	useEffect(() => {
		GetTestTypeData();
		GetCmsUrl();
		// eslint-disable-next-line
	}, []);

	const GetTestTypeData = async () => {
		try {
			const data = await getStrapiData('test-types?filters[showInPortal][$eq]=true&populate=*&sort=displayOrder:asc');
			if (data.length > 0) {
				setTestTypeList(data || []);
			}
		} catch (error) {}
	};

	const GetCmsUrl = async () => {
		try {
			const contentAPI = await getCmsURL();
			if (contentAPI) {
				setContentApi(contentAPI);
			}
		} catch (error) {}
	};

	const handleDiagnosticTest = (item: any) => {
		if (item.attributes.isActive) {
			let redirect_url = `${window.location.protocol}//dashboard.${getHostName()}/diagnostics/${item.id}`;
			dispatch(updateCommon({ redirectURL: redirect_url }));
			dispatch(updateCommon({ workflow: 'diagnostic' }));
			if (user === null) {
				navigate('/diagnostic-test');
			} else {
				window.location.replace(redirect_url);
			}
		}
	};

	const handleFullLenfthTest = (testId: string) => {
		let redirect_url = `${window.location.protocol}//dashboard.${getHostName()}/diagnostics/${testId}/fulllength`;
		dispatch(updateCommon({ redirectURL: redirect_url }));
		dispatch(updateCommon({ workflow: 'fulllength' }));
		if (user === null) {
			navigate('/confirm');
		} else if (register === null) {
			navigate('/registration');
		} else {
			window.location.replace(redirect_url);
		}
	};

	return (
		<div className='take-diagnostic'>
			<div className='take-diagnostic-inner'>
				<div className='dia-frame-parent3'>
					<div className='take-a-diagnostic-parent'>
						<div className='take-a-diagnostic-parent'>
							<div className='take-a-diagnostic'>TAKE A DIAGNOSTIC</div>
							<div className='dia-frame-item' />
						</div>
						<img className='tree-icon' alt='' src={Tree} />
					</div>
					<Grid container className='dia-frame-parent5' direction={'row'} justifyContent={'space-between'}>
						{testTypeList.map((item: any, index: number) => (
							<Grid key={index} item className='digital-sat-prep-parent'>
								<div className='digital-sat-prep-parent'>
									<div className='digital-sat-prep'>{item.attributes.Name}</div>
									<div className='dia-frame-parent-test'>
										<div className='take-a-diagnostic-parent' onClick={() => handleDiagnosticTest(item)}>
											<img
												className={`dia_icon ${!item.attributes.isActive && 'coming-soon'}`}
												alt=''
												onError={(e) => ((e.target as any).src = DiagnosticIcon)}
												src={`${contentApi}${item?.attributes?.diagnosticIcon?.data?.attributes?.url}` || DiagnosticIcon}
											/>
											<div className={`diagnostics-text ${!item.attributes.isActive ? 'coming-soon' : ''}`}>DIAGNOSTICS</div>
											{item.attributes.isActive ? '' : <span className='comingSoon'>(Coming soon)</span>}
										</div>
										<div
											className={'take-a-diagnostic-parent'}
											onClick={() => {
												handleFullLenfthTest(item.id);
											}}
										>
											<img
												className={`dia_icon ${!item.attributes.isFullLenghtTestAvailable && 'coming-soon'}`}
												alt=''
												onError={(e) => ((e.target as any).src = TestIcon)}
												src={`${contentApi}${item?.attributes?.FullLengthIcon?.data?.attributes?.url}` || TestIcon}
											/>
											<div className={`diagnostics-text ${!item.attributes.isFullLenghtTestAvailable ? 'coming-soon' : ''}`}>FULL LENGTH TEST</div>
											{item.attributes.isFullLenghtTestAvailable ? '' : <span className='comingSoon'>(Coming soon)</span>}
										</div>
									</div>
								</div>
							</Grid>
						))}
					</Grid>
				</div>
			</div>
		</div>
	);
};

export default Diagnostic;
