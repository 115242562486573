import React from 'react';
import Banner from '../Banner';
import Navbar from '../Navbar';
import Footer from '../Footer';
import './EmploymentOpportunities.css'; // Import your component-specific CSS file

const EmploymentOpportunities: React.FC = () => {
  return (
    <div className="page-container">
      <Banner />
      <Navbar />
      <h1 style={{ textAlign: 'center', fontWeight: 'bold', color: '#0146C7' }}>Employment Opportunities</h1>
      <div className="content">
        <div className="call-to-action">
          <p style={{ color: 'white', fontWeight: 'bold' }}>
            For information on becoming a part of our AlphaPrep team, please call 860-798-2817.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EmploymentOpportunities;
