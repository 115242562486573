import './Footer.css';
import Logo from '../../assets/img/common/logo.png';
import { useNavigate } from 'react-router-dom';
import { updatePaymentState } from '../../reduxstate/slices/portalSlice';
import { useDispatch } from 'react-redux';

const Footer: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const handleMakePayment = () => {
		dispatch(updatePaymentState({ isError: false, responseMessage: '' }));
		sessionStorage.setItem('payment_source_from', 'portal');
		navigate('/makePayment');
	};
	return (
		/* eslint-disable jsx-a11y/anchor-is-valid */
		<div className='footerContainer'>
			<div className='logo'>
				<img src={Logo} alt='Logo' />
			</div>
			<div className='footerLinks'>
				<a href='/privacy-policy'>Privacy Policy</a> |<a href='mailto:info@alphaprep.com'>Contact Us</a> |
				<a style={{ cursor: 'pointer' }} onClick={handleMakePayment}>
					Make a Payment
				</a>{' '}
				|<a href='/employment-opportunities'>Employment Opportunities</a>
				{/* <a href="#">Sitemap</a> */}
			</div>
			<div className='footerInfo'>Corporate Headquarters: AlphaPrep, LLC, 66 Rivergate Lane, East Berlin, CT 06023, 1-888-84-ALPHA (1-888-842-5742)</div>
		</div>
	);
};

export default Footer;
