import React, { ChangeEvent, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { FormControl, FormControlLabel } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import './CustomCheckBox.css';

const StyledCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
	'&.Mui-checked': {
		color: '#000',
	},
}));

interface CustomCheckBoxProps {
	label: string;
	name: string;
	checked: boolean;
	alignment?: string;
	children?: ReactNode;
	onChange: (event: ChangeEvent<HTMLInputElement>, ...rest: any[]) => void;
}

const CustomCheckBox: React.FC<CustomCheckBoxProps> = (props) => {
	const { name, label, onChange, checked, alignment, children, ...rest } = props;

	const handleChange = (event: ChangeEvent<HTMLInputElement>, ...rest: any[]) => {
		onChange(event, ...rest);
	};

	return (
		<FormControl className={alignment === 'center' ? 'ckb_form_root_center' : alignment === 'left' ? 'ckb_form_root_left' : 'ckb_form_root'} {...rest}>
			<FormControlLabel
				className={alignment === 'left' ? 'ckb_form_label_left' : 'ckb_form_label'}
				control={<StyledCheckbox name={name} checked={checked} onChange={handleChange}></StyledCheckbox>}
				label={<p className='ckb_label'>{label}</p>}
			/>
		</FormControl>
	);
};

export default CustomCheckBox;
