import React from 'react';
import Banner from '../Banner';
import Navbar from '../Navbar';
import Footer from '../Footer';
import './PrivacyPolicy.css';

const PrivacyPolicy: React.FC = () => {
	return (
		<div>
			<Banner />
			<Navbar />
			<div className='privacy-policy'>
				<h1 style={{ textAlign: 'center', fontWeight: 'bold', color: '#0146C7' }}>Privacy Policy for AlphaPrep, LLC</h1>
				<p>
					<strong>Introduction: </strong>
					Welcome to AlphaPrep, LLC where we are dedicated to providing exceptional educational services including test preparation, college consulting, and academic tutoring for both American and IB
					curriculums while prioritizing the privacy and security of our customers. This privacy policy outlines how we collect, use, and safeguard the personal information of our users.
				</p>
				<p>
					<strong>Types of Information Collected: </strong>
					We may collect the following types of personal information from our customers:
				</p>
				<ul>
					<li>Name</li>
					<li>Email address</li>
					<li>Contact information (phone number, address, etc.)</li>
					<li>Payment information (credit card details, billing address, etc.)</li>
					<li>Grade level</li>
					<li>Activities related to college admission</li>
				</ul>
				<p>
					<strong>How Information is Collected: </strong>
					We collect customer information through various methods, including:
				</p>
				<ul>
					<li>Directly from customers when they register for our services or interact with our website or mobile application</li>
					<li>Automatically through cookies and similar tracking technologies when customers use our website or mobile application</li>
					<li>From third-party sources, such as social media platforms, with customer consent</li>
				</ul>
				<p>
					<strong>Purpose of Data Collection: </strong>
					We collect customer information for the following purposes:
				</p>
				<ul>
					<li>To provide and personalize our educational services</li>
					<li>To process payments for tutoring and consulting services</li>
					<li>To communicate with customers about their accounts, services, and updates</li>
					<li>To improve our services</li>
					<li>To comply with legal obligations</li>
				</ul>
				<p>
					<strong>Data Security Measures: </strong>
					We take the security of customer data seriously and have implemented various measures to protect it from unauthorized access, disclosure, alteration, or destruction. These measures include:
				</p>
				<ul>
					<li>Secure transmission and storage protocols (e.g., encryption)</li>
					<li>Access controls and authentication mechanisms</li>
					<li>Employee training on data protection</li>
				</ul>
				<p>
					<strong>Data Sharing: </strong>
					We do not sell or share customer information to third parties. However, we may share customer data with trusted third parties in the following circumstances:
				</p>
				<ul>
					<li>With service providers who assist us in operating our business and providing our services (e.g., payment processors, IT service providers)</li>
					<li>With legal authorities or other third parties when required by law or to protect our rights, property, or safety</li>
				</ul>
				<p>
					<strong>User Rights: </strong>
					Customers have the following rights regarding their personal information:
				</p>
				<ul>
					<li>The right to access, update, or correct their data</li>
					<li>The right to request deletion of their data</li>
					<li>The right to opt-out of certain data processing activities</li>
					<li>The right to request a copy of their data in a commonly used electronic format Customers can exercise these rights by contacting us using the information provided below.</li>
				</ul>
				<p>
					<strong>Contact Information: </strong>
					If you have any questions, concerns, or requests regarding your privacy or this privacy policy, please contact us at: AlphaPrep, LLC, 66 River Gate Lane, +1 (888) 842-5742,{' '}
					<a href='http://www.alphaprep.com'>www.alphaprep.com</a>.
				</p>
				<p>
					<strong>SMS Consent: </strong>
					We will obtain explicit consent from customers before sending them any marketing communications via SMS. Customer consent for SMS communications will not be shared with third parties unless
					required by law or with the customer's explicit consent. Our “Opt In or Opt out” policy is completed in two ways. Customers are given a clear choice upon registering on our website to “Allow
					SMS Notifications” which they may uncheck or check. And upon receiving an SMS notification they may choose to type “Stop” to opt out of receiving any further texts.
				</p>
				<p>
					<strong>Updates to Privacy Policy: </strong>
					We may update this privacy policy from time to time to reflect changes in our business practices or legal requirements. We will notify customers of any material changes to this policy by
					posting a notice on our website or sending a notification to registered users.
				</p>
				<p>This privacy policy was last updated on May 1, 2024.</p>
			</div>
			<Footer />
		</div>
	);
};

export default PrivacyPolicy;
