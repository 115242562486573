import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import './International.css';
import internationalBg from '../../assets/img/international/international4.png';

const InternationalPaper = styled(Paper)<PaperProps>(({ theme }) => ({
	margin: '2vh 0',
	color: '#FFFF',
	height: '100vh !important',
	maxWidth: '100% !important',
	position: 'relative',
	boxShadow: 'none',
}));

const International: React.FC = () => {
	useEffect(() => {}, []);
	return (
		<InternationalPaper>
			<img src={internationalBg} alt='home-screen' className='international_bg_image' />
			<Stack direction={'column'} className='international_item_1'>
				<Typography className='international_header'>HELPING STUDENTS AND FAMILIES INTERNATIONALLY</Typography>
			</Stack>
			<Stack direction={'column'} className='international_item_2'>
				<Typography className='international_text'>
					AlphaPrep understands the added complexities faced by international students seeking admission to universities in the U.S. We work with exchange programs, consultants, and international
					schools to provide guidance through the college admission process, including SAT/ACT test prep as well as TOEFL/IELTS test prep. Our customized programs provide students with all of the
					necessary components to gain acceptance to the best U.S. colleges and Universities
				</Typography>
			</Stack>
		</InternationalPaper>
	);
};

export default International;
