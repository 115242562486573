import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CustomClass.css';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../reduxstate/index';
import { ReactComponent as CourseCodeIcon } from '../../assets/img/custom_class/coursecode.svg';
import { ReactComponent as ArrowIcon } from '../../assets/img/custom_class/arrow.svg';
import School from '../../assets/img/custom_class/school.png';
import VideoCall from '../../assets/img/custom_class/videocall.png';
import Group from '../../assets/img/custom_class/group.png';
import { useDispatch } from 'react-redux';
import { updateCommon } from '../../reduxstate/slices/portalSlice';
import { common } from '../../assets/jss/messages';
import { useOnScreen } from '../../shared';
import { getStrapiData } from '../../shared';

const CustomClass: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { mobile, emailId } = useSelector((state: RootState) => state.portal);
	const [searchcourses, setSearchcourses] = React.useState('');
	const refCourseCode = React.useRef<HTMLDivElement>(null);
	const isVisible = useOnScreen(refCourseCode);

	useEffect(() => {
		const inputElement = document.getElementById('searchcourses-input');
		const targetId = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
		if (inputElement && isVisible && targetId === 'custom-class') {
			setTimeout(() => {
				inputElement.focus();
			}, 300);
		}
	}, [isVisible]);

	const handleChangeCourses = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchcourses(event.target.value);
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			checkCourseCode();
		}
	};

	const checkCourseCode = async () => {
		if (!isEmptyOrSpaces(searchcourses)) {
			try {
				const today = new Date().toISOString().slice(0, 10);
				const filter = `filters[$and][0][code][$eqi]=${searchcourses}&filters[$and][1][isEnable][$eq]=true&filters[$and][2][endDate][$gte]=${today}`;
				const data = await getStrapiData(`courses?${filter}&populate=*&sort=endDate:asc`);
				if (data.length > 0) {
					navigateToEnrollPage(data[0].attributes.code);
				} else {
					dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.class_code_not_found } }));
				}
			} catch (error) {
				dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.class_code_not_found } }));
			}
		} else {
			dispatch(updateCommon({ snackbarDetail: { open: true, duration: 5000, severity: 'error', message: common.class_code_required } }));
		}
	};

	const navigateToEnrollPage = (code: String) => {
		navigate('/enroll?from=portal&code=' + code);
	};

	const isEmptyOrSpaces = (value: string | null | undefined) => {
		return value === undefined || value === null || value === '';
	};

	return (
		<div className='custom-classes'>
			<div className='custom-classes-inner'>
				<div className='class-frame-parent9'>
					<div className='class-frame-parent10'>
						<div className='custom-classes-parent'>
							<div className='school-sponsored'>custom classes</div>
							<div className='rectangle-div' />
						</div>
						<div className='class-frame-parent11'>
							<div className='class-frame-wrapper'>
								<div className='school-sponsored-parent'>
									<div className='school-sponsored'>school sponsored</div>
									<img className='school-1-icon' alt='' src={School} />
								</div>
							</div>
							<div className='class-frame-wrapper'>
								<div className='school-sponsored-parent'>
									<div className='school-sponsored'>Live Instructor</div>
									<img className='school-1-icon' alt='' src={VideoCall} />
								</div>
							</div>
							<div className='class-frame-wrapper'>
								<div className='school-sponsored-parent'>
									<div className='school-sponsored'>select groups</div>
									<img className='school-1-icon' alt='' src={Group} />
								</div>
							</div>
						</div>
					</div>
					<div className='achieve-your-personal'>
						Achieve your personal best on the test. AlphaPrep combines live instruction with cutting edge technology to individualize the classroom experience for each student.
					</div>
					<TextField
						label=''
						ref={refCourseCode}
						id='searchcourses-input'
						placeholder='Enter Course Code'
						className='find_course_input'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<CourseCodeIcon className='find_course_icon' />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton className='find_course_icon_bg' onClick={checkCourseCode}>
										<ArrowIcon className='find_course_icon' />
									</IconButton>
								</InputAdornment>
							),
							classes: {
								root: 'find_course_input_text',
								notchedOutline: 'find_course_input_notch_line',
							},
							style: {
								color: 'red',
							},
						}}
						inputProps={{
							sx: {
								'&::placeholder': {
									color: '#0146C7',
									opacity: 0.75,
								},
							},
						}}
						name='searchcourses'
						type='text'
						value={searchcourses}
						onChange={handleChangeCourses}
						onKeyDown={handleKeyPress}
					/>
					<div className='custom-classes-bottom'>
						<div className='need-a-course'>Need a course code?</div>
						<div>
							Contact Us at &nbsp;<a href={`mailto:${emailId}`}>{emailId}</a>&nbsp;|&nbsp;<a href={`tel:${mobile}`}>{mobile}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomClass;
